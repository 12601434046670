import type { TimelineDoctypes } from '@/features/ResultsTimeline/types'
import { COLORS, RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { RESULT_CATEGORIES } from '@/utils/constants'

export const TIMELINE_CATEGORIES = [
  RESULT_CATEGORIES.IMAGE,
  RESULT_CATEGORIES.PUBLICATION,
  RESULT_CATEGORIES.SOCIAL_MEDIA,
  RESULT_CATEGORIES.FIRE,
  RESULT_CATEGORIES.SHIP,
  RESULT_CATEGORIES.PROPERTY,
]

export const BAR_FILL_COLOR: Partial<{
  [key in TimelineDoctypes]: { normal: string; selected: string }
}> = {
  [RESULT_CATEGORIES.IMAGE]: {
    normal: COLORS.blue[4],
    selected: COLORS.blue[8],
  },
  [RESULT_CATEGORIES.PUBLICATION]: {
    normal: COLORS.orange[4],
    selected: COLORS.orange[4],
  },
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: {
    normal: '#4335B1',
    selected: '#4335B1',
  },
  [RESULT_CATEGORIES.FIRE]: {
    normal: RESULT_CATEGORY_COLORS.fire,
    selected: RESULT_CATEGORY_COLORS.fire,
  },
  [RESULT_CATEGORIES.SHIP]: {
    normal: RESULT_CATEGORY_COLORS.ship,
    selected: RESULT_CATEGORY_COLORS.ship,
  },
  [RESULT_CATEGORIES.PROPERTY]: { normal: '#C2185B', selected: '#C2185B' },
}

export const FOCUS_HIST_HEIGHT = 60
export const INNER_MARGIN = 10
export const CONTEXT_HIST_HEIGHT = 25
export const SVG_TOP_PADDING = 20

export const CHART_HEIGHT =
  FOCUS_HIST_HEIGHT + CONTEXT_HIST_HEIGHT + INNER_MARGIN + SVG_TOP_PADDING

export const BUCKET_MAX_THRESHOLD = 100

export const timeBucketUnitKeys = [
  'HOUR',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
] as const

export const TimeBucketUnit: {
  [key in (typeof timeBucketUnitKeys)[number]]: string
} = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
} as const
