import { useRef } from 'react'
import { Stack } from '@mantine/core'
import { useMapboxMap } from '@/features/DantiMap/hooks'
import type { FeatureCollection } from 'geojson'
import { useTerrapinLocationsLayer } from './use-terrapin-locations-layer'

export interface TerrapinDynamicMapDisplayProps {
  aois: FeatureCollection
  bbox: mapboxgl.LngLatBoundsLike
}

export const TerrapinDynamicMap = ({
  aois,
  bbox,
}: TerrapinDynamicMapDisplayProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  useMapboxMap(map, mapContainer, { bounds: bbox })
  useTerrapinLocationsLayer(map, aois)

  return (
    <Stack>
      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: '400px', width: '100%' }}
      />
    </Stack>
  )
}
