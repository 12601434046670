import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Anchor, Box, Button, Text, Title } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useLastAttemptedRoute } from '@/features/AppRouting/hooks'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription.ts'
import { isGov } from '@/utils/constants'

import styles from './access-denied.module.css'

export function AccessDenied() {
  const { refreshUser, isAuthorizedUser, isLoading } = useDantiAuth()
  const { hasActiveSubscription } = useHasActiveSubscription()
  const [lastAttemptedRoute, setLastAttemptedRoute] = useLastAttemptedRoute()
  const navigate = useNavigate()
  const supportEmail = isGov ? 'gov-support@danti.ai' : 'support@danti.ai'

  useEffect(() => {
    async function handleRefreshUser() {
      await refreshUser()

      if (isAuthorizedUser() && !isLoading && hasActiveSubscription) {
        const temporaryLastAttemptedRoute = lastAttemptedRoute ?? '/'
        setLastAttemptedRoute(null)
        navigate(temporaryLastAttemptedRoute)
      }
    }

    void handleRefreshUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box bg="gray.9" mih="100%" w="100%" pos="absolute" top={0} left={0}>
      <Box
        className={styles.accessDeniedContent}
        py="xl"
        px="lg"
        maw="100%"
        bg="gray.9"
      >
        <Title order={1} size={48} fw={400} c="white">
          403
        </Title>
        <Title order={2} size={48} fw={400} c="white" mb={32}>
          Access denied
        </Title>
        <Text c="white" mb={32} size="lg">
          You may need to contact customer support{' '}
          <Anchor href={`mailto:${supportEmail}`} c="yellow">
            {supportEmail}
          </Anchor>{' '}
          to start your subscription/contract.
        </Text>
        <Button
          component="a"
          href={`mailto:${supportEmail}`}
          leftSection={<Icon name="mail" size={16} />}
          variant="outline"
          color="yellow"
          size="md"
          styles={{
            root: {
              borderColor: 'var(--mantine-color-yellow-6)',
              color: 'var(--mantine-color-yellow-6)',
              '&:hover': {
                backgroundColor: 'var(--mantine-color-yellow-9)',
              },
            },
          }}
        >
          Contact Customer Support
        </Button>
      </Box>
    </Box>
  )
}
