import { type MutableRefObject, useEffect, useState } from 'react'
import { emitter, EVENTS } from '@/events'
import { actOnMap, safeSetLayoutProperty } from '@/features/DantiMap/utils'
import { useSearchStatus } from '@/features/Search/hooks'
import {
  MAP_LAYER_OPTIONS,
  type MapLayerOptions,
  useActiveLayers,
} from '@/stores/map-store'

export const useHandleMapToggling = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const [isResultsExpanded, setIsResultsExpanded] = useState(false)
  const [visiblePointLayers, setVisiblePointLayers] = useState<
    MapLayerOptions[]
  >([])
  const [showExtents, setShowExtents] = useState(true)
  const { isSearching } = useSearchStatus()
  const activeLayers = useActiveLayers()

  const handleSetLayers = ({ activeLayers }: { activeLayers: string[] }) => {
    // Filter out images layer as it is not a point layer
    setVisiblePointLayers(
      activeLayers.filter((layer) => layer !== MAP_LAYER_OPTIONS.IMAGES),
    )

    // If activeLayers includes "images", show extents
    setShowExtents(activeLayers.includes(MAP_LAYER_OPTIONS.IMAGES))
  }

  useEffect(() => {
    if (showExtents !== undefined) {
      actOnMap(() => {
        const visValue = showExtents ? 'visible' : 'none'
        void safeSetLayoutProperty(map, 'extents-fills', 'visibility', visValue)
        void safeSetLayoutProperty(map, 'extent-point', 'visibility', visValue)
        void safeSetLayoutProperty(
          map,
          'extents-outlines',
          'visibility',
          visValue,
        )
      })
    }
  }, [map, showExtents])

  useEffect(() => {
    setIsResultsExpanded(isSearching)
  }, [isSearching])

  useEffect(() => {
    handleSetLayers({ activeLayers })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    emitter.on(EVENTS.mapStore.activeLayersUpdated, handleSetLayers)
    return () => {
      emitter.off(EVENTS.mapStore.activeLayersUpdated, handleSetLayers)
    }
  }, [map, showExtents, visiblePointLayers])

  const toggleResultsPanel = () => {
    setIsResultsExpanded((state) => !state)
  }

  return {
    isResultsExpanded,
    visiblePointLayers,
    showExtents,
    toggleResultsPanel,
  }
}
