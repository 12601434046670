import React from 'react'
import type { SearchCategory } from '@/api/results'
import type { QueryAndDataState } from '@/features/BulkSearch/types.ts'
import { initialUIFilterState } from '@/stores/filters-store/store.ts'
import type { DateValue } from '@/utils/types/filter-types.ts'

export function useQueryAndDataState(): QueryAndDataState {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [savedSearchName, setSavedSearchName] = React.useState('')

  const [categories, setCategories] = React.useState<SearchCategory[]>(
    initialUIFilterState.categories,
  )

  const [timePreset, setTimePreset] = React.useState(
    initialUIFilterState.timePreset,
  )
  const [customTime, setCustomTime] = React.useState<[DateValue, DateValue]>([
    initialUIFilterState.customTimeStart,
    initialUIFilterState.customTimeEnd,
  ])

  function resetQueryAndDataState() {
    setSearchQuery('')
    setSavedSearchName('')
    setTimePreset(initialUIFilterState.timePreset)
    setCustomTime([
      initialUIFilterState.customTimeStart,
      initialUIFilterState.customTimeEnd,
    ])
    setCategories(initialUIFilterState.categories)
  }

  return {
    searchQuery,
    setSearchQuery,
    savedSearchName,
    setSavedSearchName,
    categories,
    setCategories,
    timePreset,
    setTimePreset,
    customTime,
    setCustomTime,
    resetQueryAndDataState,
  }
}
