import { Card, Group, Pill, Stack, Text } from '@mantine/core'
import type { Collection, CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon.tsx'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import { CollectionMenu } from '@/features/Collections/views/Collections/CollectionMenu.tsx'
import { ICONS } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { isSubCollection } from '@/utils/types/result-type-checkers.ts'

const collectionDatetime = (collection: Collection | CollectionItem) =>
  formatDatetime((collection.authoredOn || collection.createdOn).toString())

interface CollectionCardProps {
  collectionId: string
  collection: Collection | CollectionItem
}
export const CollectionCard = ({
  collectionId,
  collection,
}: CollectionCardProps) => {
  const folderIconStyle = { style: { color: 'blue.7' }, size: 32 }
  const icons = {
    folder: <Icon name={ICONS.folder} {...folderIconStyle} />,
    specialFolder: <Icon name={ICONS.folderSpecial} {...folderIconStyle} />,
    update: <Icon name={ICONS.update} size="xs" />,
  }
  const navigateToCollection = useNavigateToCollection()
  const onCollectionClick = (collectionId: string) =>
    navigateToCollection(collectionId)

  return (
    <Card
      p="sm"
      withBorder
      radius="md"
      bg="gray.0"
      style={{ cursor: 'pointer' }}
    >
      <Stack justify="space-between" mah={200}>
        <Stack gap="xs" onClick={() => onCollectionClick(collectionId)}>
          <Group wrap="nowrap" gap="sm" align="start">
            {collection.refId ? icons.specialFolder : icons.folder}
            <Stack gap="xs" align="start">
              <Text c="gray.8" lineClamp={1} fz={18} fw="bold">
                {collection.name}
              </Text>

              <Group gap="xxs">
                {icons.update}
                <Text size="xs" c="dimmed">
                  {collectionDatetime(collection)}
                </Text>
              </Group>
            </Stack>
            {!isSubCollection(collection) && (
              <CollectionMenu collection={collection} orientation="vertical" />
            )}
          </Group>

          <Group>
            {collection.status === 'NEW' && (
              <Pill size="xs" bg="yellow.5" fw="bold">
                NEW
              </Pill>
            )}
          </Group>
        </Stack>
      </Stack>
    </Card>
  )
}
