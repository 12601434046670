import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(minMax)

export const formatDatetime = (datetime: string): string =>
  dayjs(datetime).format('MM/DD/YYYY [at] hh:mm A z')

export const formatDate = (datetime: string): string =>
  dayjs(datetime).format('YYYY-MM-DD')

export const formatTime = (datetime: string): string =>
  dayjs(datetime).format('HH:mm z')

export const fromNow = (datetime: string): string => dayjs(datetime).fromNow()

export const formatDatetimeWithWordMonths = (datetime: string): string =>
  dayjs(datetime).format('MMM D, HH:mm')

export const formatDateWithWordMonths = (datetime: string): string =>
  dayjs(datetime).format('MMM D, YYYY')

export const isValidDatetime = (datetime: string): boolean =>
  dayjs(datetime).isValid()

export const hourlyFrequency = (hour: number) =>
  `DAILY(${dayjs().hour(hour).minute(0).format('HH:mm')})`

export const datetime = (entry: string | Date) => dayjs(entry)

export const findMinDate = (dates: string[]) =>
  dayjs.min(dates.map((date) => dayjs(date)))

export const findMaxDate = (dates: string[]) =>
  dayjs.max(dates.map((date) => dayjs(date)))
