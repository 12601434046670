import type {
  LocationApiFilterValue,
  LocationTypeApiFilterValue,
} from '@/utils/types/filter-types'
import { postSearchRequest } from '../danti-requests'
import type {
  ResolveLocationsResponse,
  ValidateLocationResponse,
} from './types'

const getFormData = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

export const resolveLocations = async (payload: string | File) => {
  const isFile = payload instanceof File
  const contentType = isFile ? 'multipart/form-data' : 'text/plain'
  const content = isFile ? getFormData(payload) : payload

  return postSearchRequest<ResolveLocationsResponse>(
    'v2/multi/resolve',
    content,
    { headers: { 'Content-Type': contentType } },
  )
}

export const validateLocation = async (
  filters: [LocationApiFilterValue, LocationTypeApiFilterValue],
) => {
  return postSearchRequest<ValidateLocationResponse>('v2/query/validate', {
    filters,
  })
}
