import { SimpleGrid } from '@mantine/core'
import { useCollections } from '@/api/collections'
import { CollectionCard } from '@/features/Collections/components/CollectionCard.tsx'

export function CollectionGrid() {
  const { collections } = useCollections()
  return (
    <SimpleGrid cols={3} spacing="md" flex={1} mt="md">
      {collections.map((collection) => (
        <CollectionCard
          key={collection.id}
          collection={collection}
          collectionId={collection.id}
        />
      ))}
    </SimpleGrid>
  )
}
