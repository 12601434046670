import { Tabs, type TabsTabProps } from '@mantine/core'
import { BAR_FILL_COLOR } from '@/features/ResultsTimeline/constants'
import { TABS_MAP } from './constants'

import style from './results-panel.module.css'

export function Tab({
  total,
  value,
  ...props
}: TabsTabProps & {
  total: number
  value: keyof typeof TABS_MAP
}) {
  const tabConst = TABS_MAP[value] as keyof typeof BAR_FILL_COLOR
  const tabColor =
    BAR_FILL_COLOR[tabConst]?.normal || 'var(--mantine-color-black)'

  return (
    <Tabs.Tab
      {...props}
      value={value}
      tt="uppercase"
      className={style.tab}
      leftSection={
        <span
          className={style.leftSection}
          style={{ backgroundColor: tabColor }}
        />
      }
      disabled={total === 0}
      data-cy="result-tab"
    >
      {value}
    </Tabs.Tab>
  )
}
