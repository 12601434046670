import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Stepper,
  Title,
} from '@mantine/core'
import { useRefreshCollections } from '@/api/collections'
import { SEARCH_CATEGORIES } from '@/api/results'
import {
  type BulkSearchGeolocation,
  type BulkSearchGeolocationWithId,
  resolveLocations,
} from '@/api/search'
import ErrorIcon from '@/assets/error-circles.svg?react'
import FolderIcon from '@/assets/folder-circles.svg?react'
import { Icon } from '@/components/lib/Icon'
import { StatusBlock } from '@/components/lib/StatusBlock'
import { ImageFilters } from '@/features/BulkSearch/components/ImageFilters.tsx'
import { useBulkSearch } from '@/features/BulkSearch/hooks/use-bulk-search.ts'
import { LocationStatuses } from '@/features/BulkSearch/types.ts'
import { ImageProvidersCheckboxes } from '@/features/ImageProvidersCheckboxes'
import { QueryAndData } from '@/features/QueryAndData'
import { ROUTES } from '@/utils/constants'
import { TimePreset } from '@/utils/types/filter-types.ts'
import { AddLocations } from './components'

import styles from './advanced-search.module.css'

interface AdvancedSearchProps {
  opened: boolean
  handleClose: () => void
}

// Only store locations that are valid (meaning they have filters).
// Attach _id to each location to assist with rendering.
const formatLocations = (
  locations: BulkSearchGeolocation[],
): BulkSearchGeolocationWithId[] =>
  locations.reduce(
    (
      locationsList: BulkSearchGeolocationWithId[],
      location: BulkSearchGeolocation,
    ) => {
      if (location.filters) {
        locationsList.push({ ...location, _id: crypto.randomUUID() })
      }

      return locationsList
    },
    [],
  )

const COMPLETED_STATUS = {
  SAVING: 'SAVING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const
type CompletedStatus = ValueOf<typeof COMPLETED_STATUS>

export function AdvancedSearch({ opened, handleClose }: AdvancedSearchProps) {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [highestStepVisited, setHighestStepVisited] = useState(activeStep)
  const [completedStatus, setCompletedStatus] = useState<CompletedStatus>(
    COMPLETED_STATUS.SAVING,
  )
  const [createdCollectionId, setCreatedCollectionId] = useState('')

  const {
    locationsState,
    queryAndDataState,
    imageProvidersState,
    imageFiltersState,
    saveBulkAoiSearch,
    resetAll,
  } = useBulkSearch()
  const refreshCollections = useRefreshCollections()

  const reset = () => {
    setActiveStep(0)
    setHighestStepVisited(0)
    setCompletedStatus(COMPLETED_STATUS.SAVING)
    setCreatedCollectionId('')
    resetAll()
  }

  const saveSearch = () => {
    if (completedStatus !== 'SAVING') {
      setCompletedStatus('SAVING')
    }

    saveBulkAoiSearch()
      .then(({ data }) => {
        setCompletedStatus('SUCCESS')
        setCreatedCollectionId(data.data.collection_id)
        refreshCollections()
      })
      .catch(() => {
        setCompletedStatus('ERROR')
      })
  }

  const STEPS = [
    {
      stepperLabel: 'Locations',
      stepperDescription: 'Upload and verify locations',
      title: 'Add your locations',
      content: <AddLocations {...locationsState} />,
    },
    {
      stepperLabel: 'Query and Data',
      stepperDescription: 'Add a query and select data types',
      title: 'Add a search query and select data',
      content: <QueryAndData {...queryAndDataState} />,
    },
    {
      stepperLabel: 'Image Providers',
      stepperDescription: 'Satellite imagery Providers',
      title: 'Image providers',
      content: (
        <ImageProvidersCheckboxes
          providers={imageProvidersState.providers}
          sensors={imageProvidersState.sensors}
          platforms={imageProvidersState.platforms}
          sources={imageProvidersState.sources}
          setter={imageProvidersState.imageProvidersSetter}
          resetFn={imageProvidersState.resetImageProvidersState}
        />
      ),
    },
    {
      stepperLabel: 'Image Filters',
      stepperDescription: 'Add optional image filters',
      title: 'Image filters',
      content: <ImageFilters {...imageFiltersState} />,
    },
  ]

  const COMPLETED_STEP = {
    [COMPLETED_STATUS.SAVING]: {
      title: 'Saving your search',
      icon: <Loader />,
      description: 'Please wait just a moment.',
    },
    [COMPLETED_STATUS.SUCCESS]: {
      title: 'Setup is complete',
      icon: <FolderIcon />,
      description: (
        <>
          Danti is gathering the results of your Saved Search.
          <br />
          We will create a Saved Search folder and add the results for each
          location inside.
        </>
      ),
    },
    [COMPLETED_STATUS.ERROR]: {
      title: 'Unable to save your search',
      icon: <ErrorIcon />,
      description: (
        <>
          An error has occurred, but we can&apos;t determine the cause.
          <br />
          Go back to the previous step and try again. If the problem persists,
          email <Anchor href="mailto:help@danti.ai">help@danti.ai</Anchor>
        </>
      ),
    },
  }

  // Image filter is off if the array is not empty (empty means "all")
  // AND the image is not in this list
  const imageFilterOff =
    queryAndDataState.categories.length > 0 &&
    !queryAndDataState.categories.includes(SEARCH_CATEGORIES.IMAGE)

  // Step 1a conditions
  const isLocationsLoading = locationsState.status === LocationStatuses.LOADING
  const isEmptyInput =
    !locationsState.locationsInputValue && !locationsState.locationsFile
  const hasLocationInputErrors = Boolean(locationsState.locationsError)

  // Step 1b conditions
  const validButNoLocations =
    locationsState.status === LocationStatuses.VALIDATED &&
    locationsState.locationsList.length === 0
  const locationsButNotAllValid = locationsState.locationsList.some(
    (location) => location.error || !location.metadata,
  )

  const isStep1NextDisabled =
    activeStep === 0 &&
    (isLocationsLoading ||
      isEmptyInput ||
      hasLocationInputErrors ||
      validButNoLocations ||
      locationsButNotAllValid)

  // Step 2 conditions
  const hasNoName = queryAndDataState.savedSearchName.trim().length === 0
  const customTimeNotSet =
    queryAndDataState.timePreset === TimePreset.Custom &&
    queryAndDataState.customTime.includes(null)

  const isStep2NextDisabled =
    activeStep === 1 && (hasNoName || customTimeNotSet)

  const isCompletedStepNextDisabled =
    activeStep === STEPS.length &&
    COMPLETED_STATUS[completedStatus] !== COMPLETED_STATUS.SUCCESS

  const nextStep = () =>
    setActiveStep((current) => {
      // If on Completed Step, navigate user to created collection
      if (current === STEPS.length) {
        navigate(`${ROUTES.collections}/${createdCollectionId}`)
        return 0
      }

      // Step 1a
      if (current === 0 && locationsState.locationsList.length === 0) {
        locationsState.setStatus(LocationStatuses.LOADING)

        const payload =
          locationsState.locationsInputValue.length > 0
            ? locationsState.locationsInputValue
            : (locationsState.locationsFile ?? '')

        resolveLocations(payload)
          .then(({ data }) => {
            if (data.geolocations.length === 0) {
              throw new Error('No locations')
            }

            locationsState.setLocationsList(formatLocations(data.geolocations))
            locationsState.setStatus(LocationStatuses.VALIDATED)
          })
          .catch(() => {
            locationsState.setLocationsError(
              'There was an error validating your input. Please try again.',
            )
            locationsState.setStatus(LocationStatuses.FRESH)
          })

        return current
      }

      // Step 2 with images excluded
      if (current === 1 && imageFilterOff) {
        setHighestStepVisited(STEPS.length)

        saveSearch()
        return STEPS.length
      }

      // Step 4 with images included
      if (current === STEPS.length - 1 && !imageFilterOff) {
        saveSearch()
        return current + 1
      }

      // All other steps
      setHighestStepVisited((hSC) => Math.max(hSC, current + 1))
      return current + 1
    })

  const previousStep = () =>
    setActiveStep((current) => {
      // If on Step 1, close the modal
      if (current === 0) {
        handleClose()
        reset()
        return current
      }

      // If on Step 2 and the Image Filter is off, go back to Step 1
      if (imageFilterOff && current > 1) {
        setHighestStepVisited(1)
        return 1
      }

      // If on Completed Step and the Search is created, close modal
      if (
        activeStep === STEPS.length &&
        COMPLETED_STATUS[completedStatus] === COMPLETED_STATUS.SUCCESS
      ) {
        handleClose()
        reset()
        return 0
      }

      // Otherwise, set the active step to previous
      return current - 1
    })

  const shouldAllowSelectStep = (step: number) => {
    // Prevents disabled styles on first step
    if (activeStep === step) return true

    // If on step 2 and the image filter is off, prevent user from going to step 3 and 4
    if (step > 1 && imageFilterOff) return false

    // Allow the user to freely go back and forth between *visited* steps
    return highestStepVisited >= step
  }

  const disabledStepIcon = <Icon name="close" size="2rem" weight={700} />

  const getNextButtonText = () => {
    if (activeStep === STEPS.length - 1) return 'Finish'
    if (activeStep === STEPS.length) return 'Go to the folder'

    return 'Next'
  }

  return (
    <Modal
      size="90%"
      opened={opened}
      onClose={() => {
        handleClose()
        reset()
      }}
      classNames={{
        content: styles.modalContent,
        body: styles.modalBody,
        header: styles.modalHeader,
      }}
      padding="xl"
      closeOnClickOutside={false}
    >
      <Stepper active={activeStep} onStepClick={setActiveStep}>
        {STEPS.map((step, index) => (
          <Stepper.Step
            key={step.stepperLabel}
            className={shouldAllowSelectStep(index) ? '' : styles.disabledLabel}
            allowStepSelect={shouldAllowSelectStep(index)}
            maw={200}
            label={step.stepperLabel}
            description={step.stepperDescription}
            {...(imageFilterOff &&
              index > 1 && {
                icon: disabledStepIcon,
                completedIcon: disabledStepIcon,
                color: 'gray.3',
              })}
          >
            <Title size={32}>{step.title}</Title>
            <Divider my="md" />
            {step.content}
          </Stepper.Step>
        ))}
        <Stepper.Completed>
          <StatusBlock {...COMPLETED_STEP[completedStatus]} />
        </Stepper.Completed>
      </Stepper>
      <Box>
        <Divider my="md" />
        <Group justify="space-between">
          <Button
            disabled={
              (activeStep === 0 &&
                locationsState.status === LocationStatuses.LOADING) ||
              (activeStep === STEPS.length &&
                COMPLETED_STATUS[completedStatus] === COMPLETED_STATUS.SAVING)
            }
            variant="outline"
            onClick={previousStep}
          >
            {COMPLETED_STATUS[completedStatus] === COMPLETED_STATUS.SUCCESS
              ? 'Close'
              : activeStep > 0
                ? '< Back'
                : 'Cancel'}
          </Button>
          <Button
            disabled={
              isStep1NextDisabled ||
              isStep2NextDisabled ||
              isCompletedStepNextDisabled
            }
            onClick={nextStep}
          >
            {getNextButtonText()}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
