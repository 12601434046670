import { useFeatureFlag } from '@/hooks/use-feature-flag.ts'
import { FEATURE_FLAG_VALUES } from '@/utils/constants.ts'
import { useConfigStore } from './store'

export const useShowScores = () => {
  const stateValue = useConfigStore((state) => state.showScores)
  const flagValue = useFeatureFlag(FEATURE_FLAG_VALUES.showScores)

  return flagValue && stateValue
}
export const useToggleShowScores = () =>
  useConfigStore((state) => state.toggleShowScores)
