import { Image } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import type { TerrapinLocation } from '@/hooks/use-terrapin'

export interface TerrapinStaticMapDisplayProps {
  location: TerrapinLocation
  onClick: () => void
}

export const TerrapinStaticMapDisplay = ({
  location,
  onClick,
}: TerrapinStaticMapDisplayProps) => {
  if (!location.metadata?.geometry) return

  const staticMapUrl = createStaticMapUrl({
    geometry: location.metadata.geometry,
    size: [125, 80],
    attribution: false,
    zoom: 1,
  })

  return (
    <Image
      src={staticMapUrl}
      alt="Geometry"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    />
  )
}
