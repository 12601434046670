import type { SearchCategory } from '@/api/results'
import type { CategoryApiFilterValue } from '../types/filter-types'

export const makeCategoryFilter = (
  categories: SearchCategory[],
): CategoryApiFilterValue => {
  return {
    type: 'CATEGORY',
    ids: [...categories],
  }
}
