import React from 'react'
import type { BulkSearchGeolocationWithId } from '@/api/search'
import {
  type LocationsState,
  type LocationStatus,
  LocationStatuses,
} from '../types'

export function useLocationsState(): LocationsState {
  const [locationsInputValue, setLocationsInputValue] = React.useState('')
  const [locationsFile, setLocationsFile] = React.useState<File | null>(null)
  const [locationsList, setLocationsList] = React.useState<
    BulkSearchGeolocationWithId[]
  >([])
  const [locationsError, setLocationsError] = React.useState<string | null>(
    null,
  )
  const [status, setStatus] = React.useState<LocationStatus>(
    LocationStatuses.FRESH,
  )

  function resetLocationsState() {
    setLocationsInputValue('')
    setLocationsFile(null)
    setLocationsList([])
    setStatus(LocationStatuses.FRESH)
  }

  return {
    locationsInputValue,
    setLocationsInputValue,
    locationsFile,
    setLocationsFile,
    locationsList,
    setLocationsList,
    locationsError,
    setLocationsError,
    status,
    setStatus,
    resetLocationsState,
  }
}
