/* eslint-disable camelcase */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebouncedValue } from '@mantine/hooks'
import { handleCollectionLocationDownload } from '@/api/collections'
import { useCollectionItems } from '@/api/collections/collection-items'
import type {
  Collection,
  TerrapinConfigResponse,
} from '@/api/collections/types'
import { getRequest } from '@/api/danti-requests'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import {
  openSaveSearchModal,
  openShareModal,
} from '@/features/AppLayout/Modals'
import { formatLocalNotificationSchedule } from '@/features/AppLayout/utils'
import { useProviders } from '@/features/Filters'
import { useCollectionItemsState } from '@/stores/collection-items'
import { useSetFilters } from '@/stores/filters-store'
import {
  useSetSavedSearchId,
  useSetSavedSearchName,
} from '@/stores/queries-store'
import { useAvatarDetails } from '@/stores/user-store'
import { parseFilters } from '@/utils/search-filters/parse-filters'
import type { SearchApiFilterValue } from '@/utils/types/filter-types'
import { useQuery } from '@tanstack/react-query'

export function useCollectionDetailHeader({
  collection,
}: {
  collection: Collection
}) {
  const { search } = useCollectionItemsState()
  const { totalCount, filter } = useCollectionItems(collection.id)

  const [searchTerm, setSearchTerm] = React.useState('')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)
  const setFilters = useSetFilters()
  const { email } = useAvatarDetails()
  const { newProviders } = useProviders()
  const setQueryId = useSetSavedSearchId()
  const setSavedSearchName = useSetSavedSearchName()
  const navigate = useNavigate()

  const shareLabel = React.useMemo(() => {
    const count = collection.users.length - 1

    if (count === 0) {
      return 'Share'
    } else if (count == 1) {
      return 'Shared with 1 person'
    } else {
      return `Shared with ${count} people`
    }
  }, [collection])

  const disableShare = React.useMemo(() => {
    const role = collection.users.find((user) => user.email === email)?.role
    if (!role) {
      return true
    }
    return role !== 'creator' && role !== 'owner'
  }, [collection, email])

  React.useEffect(() => {
    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  const { data: terrapinResponse } = useQuery({
    queryKey: ['terrapin', collection.refId],
    queryFn: () =>
      getRequest<TerrapinConfigResponse>(`/terrapin?id=${collection.refId}`),
    enabled: !!collection.refId,
  })

  const openSettings = () => {
    // BENNETT / ROB how come save searches can't resolve terrapin?
    // http://localhost:4200/collections/05914734-495c-4b92-b92e-384260282d60
    if (!terrapinResponse) {
      return
    }

    const {
      configs: {
        name,
        query,
        expiration,
        email_notification,
        backfill,
        op_mode,
        locations,
        ...payload
      },
    } = terrapinResponse.data

    const filters = parseFilters(payload.filters)
    setFilters(filters)

    const localScheduledTime =
      formatLocalNotificationSchedule(email_notification)

    openSaveSearchModal({
      refId: collection.refId,
      isNew: false,
      providers: newProviders,
      params: {
        name,
        query,
        emailNotification: localScheduledTime,
        expiration,
        backfill,
        mode: op_mode,
        locations,
        filters: payload.filters as SearchApiFilterValue[],
      },
    })
  }

  const handleExploreResultsClick = () => {
    if (!terrapinResponse?.data.configs?.queryId) {
      return
    }
    setSavedSearchName(
      terrapinResponse.data.configs.query || terrapinResponse.data.configs.name,
    )
    setQueryId(terrapinResponse.data.configs.queryId)
    navigate('/overview?search')
  }

  const handleDownloadClick = () => {
    void handleCollectionLocationDownload({
      collectionId: collection.id,
      collectionName: collection.name,
      totalCount,
      filter,
      searchTerm,
    })
  }

  const handleShareClick = () => {
    openShareModal(collection)
  }

  const shareTooltip = disableShare
    ? 'You do not have access to share this collection'
    : undefined

  return {
    disableShare,
    handleDownloadClick,
    handleShareClick,
    handleUploadClick: () => openFileUploadModal(null),
    openSettings,
    searchTerm,
    setSearchTerm,
    shareLabel,
    shareTooltip,
    terrapinConfig: terrapinResponse?.data.configs,
    handleExploreResultsClick,
  }
}
