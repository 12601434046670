import { Stack } from '@mantine/core'
import type { ActionMenuOption } from '@/features/ResultActions/types'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { SOVActions } from './Actions'
import type { /*SOVCarousel,*/ SOVCarouselProps } from './Carousel'
import { SOVContent, type SOVContentProps } from './Content'
import { SOVHero, type SOVHeroProps } from './Hero'

import styles from './view-template.module.css'

export type { SOVCarouselProps } from './Carousel'
export type { SOVContentProps } from './Content'
export type { SOVHeroProps } from './Hero'

export interface SingleObjectViewTemplateProps<ItemType, CarouselType> {
  item: ItemType
  hideActions: ActionMenuOption[]
  carousel?: SOVCarouselProps<CarouselType>
  content?: SOVContentProps
  hero: Omit<SOVHeroProps<ItemType>, 'item'>
}

export function SingleObjectViewTemplate<IT, CT>({
  item,
  hideActions = [],
  // carousel,
  content,
  hero,
}: SingleObjectViewTemplateProps<IT, CT>) {
  // Ensure SOV navigation events are captured in PostHog
  function toNext() {
    captureEvent(POSTHOG_EVENTS.DETAIL.NAV_NEXT)
    return hero.toNext?.()
  }
  function toPrevious() {
    captureEvent(POSTHOG_EVENTS.DETAIL.NAV_PREV)
    return hero.toPrevious?.()
  }
  const heroWithCapture = { ...hero, toNext, toPrevious }

  return (
    <Stack className={styles['object-view-container']}>
      <SOVActions result={item} hideActions={hideActions} />
      <SOVHero {...heroWithCapture} item={item} />
      {content && <SOVContent {...content} />}
      {/*{carousel && <SOVCarousel {...carousel} />}*/}
    </Stack>
  )
}
