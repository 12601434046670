import React from 'react'
import { Card, type CardProps } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { ScoreDisplay } from '@/components/lib/ScoreDisplay.tsx'
import { useMenuOptionActions } from '@/features/ResultActions/hooks'
import { useShowScores } from '@/stores/config-store'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'

import styles from './result-base.module.css'

interface ResultBaseProps extends CardProps {
  item?: CollectionItem
  result: ResultWithId
  children?: React.ReactNode
  className?: string
  disableNavigate?: boolean
}

export const ResultBase = React.forwardRef(
  (
    {
      result,
      children,
      className,
      item,
      disableNavigate,
      ...props
    }: ResultBaseProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const showScores = useShowScores()

    const actions = useMenuOptionActions({ result, item })
    return (
      <Card
        ref={forwardedRef}
        onClick={(event) => {
          captureEvent(POSTHOG_EVENTS.RESULTS.CLICKED, {
            resultCategory: result.properties.resultCategory ?? result.category,
          })
          actions.viewDetails(event)
        }}
        className={classes(styles['hidden-panel'], className)}
        radius={0}
        pos="relative"
        p={0}
        pb={showScores ? 'sm' : 0}
        fz="sm"
        c="gray.5"
        {...props}
      >
        {children}
        <ScoreDisplay result={result} />
      </Card>
    )
  },
)

ResultBase.displayName = 'ResultBase'
