import {
  Group,
  Loader,
  Pagination,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core'
import {
  PAGE_SIZE,
  usePaginatedResults,
  useResultTotals,
  useSearchStatus,
} from '@/api/results'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import { FilterToggleButton } from '@/features/Filters'
import { EmbeddedInsightPanel } from '@/features/InsightPanel'
import { ResultsTimelineContainer } from '@/features/ResultsTimeline'
import {
  useSavedSearchId,
  useSetCategoryPage,
  useSetSortPreference,
  useSortPreference,
} from '@/stores/queries-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import { useResultTabs } from './use-result-tabs'

import style from './results-panel.module.css'

const PENDING_STRING =
  "We're searching our sources for newer data. Please wait."
const ERROR_STRINGS = [
  "Danti searched everywhere, but couldn't find any images, news, or social media.",
  'Please modify your search and try again.',
]

export function ResultsPanel() {
  const { activeCategory, activeTab, handleTabChange, orderedTabs } =
    useResultTabs()

  const sortPreference = useSortPreference()
  const setSortPreference = useSetSortPreference()
  const { categorized: timelineCategorized } = useTimelineFilteredResults()
  const status = useSearchStatus()

  const handleSortChange = (value: string | null) =>
    setSortPreference(value ? (value as 'authoredOn' | 'score') : 'authoredOn')
  const sortValues = [
    { label: 'Sort by Date', value: 'authoredOn' },
    { label: 'Sort by Relevance', value: 'score' },
  ]
  const totals = useResultTotals()
  const setCurrentPage = useSetCategoryPage()
  const results =
    timelineCategorized[activeCategory as keyof typeof timelineCategorized]
  const page = usePaginatedResults().pages[activeCategory]
  const savedSearchId = useSavedSearchId()
  const isSavedSearch = savedSearchId !== ''

  const filterToggleStyleProps = isSavedSearch
    ? undefined
    : {
        c: 'black',
        color: 'gray.4',
      }

  return (
    <>
      {timelineCategorized[SEARCH_CATEGORIES.INSIGHT].length > 0 && (
        <EmbeddedInsightPanel
          result={timelineCategorized[SEARCH_CATEGORIES.INSIGHT][0]}
        />
      )}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className={style.resultsPanel}
        keepMounted={false}
      >
        <Tabs.List>{orderedTabs.map((config) => config.tab)}</Tabs.List>
        <ResultsTimelineContainer isSmall />
        <Group
          className={style.filterGroup}
          justify="space-between"
          h="3rem"
          align="center"
          gap="xs"
        >
          <FilterToggleButton
            size="xs"
            variant="outline"
            {...filterToggleStyleProps}
          >
            Filters
          </FilterToggleButton>
          <Select
            size="xs"
            value={sortPreference}
            onChange={handleSortChange}
            data={sortValues}
            maw={140}
            data-cy="sort-results"
          />
        </Group>
        {['pending', 'error'].includes(status) ? (
          <Stack align="center" gap="xs" mt="xl">
            {status === 'pending' && <Loader size="lg" mb="md" />}
            <Title fw={500}>
              {status === 'pending' ? 'Exploring new data' : 'No results'}
            </Title>
            <Text size="sm" c="gray" ta="center">
              {status === 'pending' ? (
                PENDING_STRING
              ) : (
                <>
                  {ERROR_STRINGS[0]}
                  <br />
                  {ERROR_STRINGS[1]}
                </>
              )}
            </Text>
          </Stack>
        ) : (
          orderedTabs.map((tab) => (
            <Tabs.Panel
              className={style.panel}
              data-cy="result-panel"
              key={tab.value}
              value={tab.value}
            >
              <div className={style.grid}>{tab.content}</div>
            </Tabs.Panel>
          ))
        )}
        {results.length > 0 && (
          <Group mt="sm" mb="sm" gap={6} justify="space-between">
            {totals[activeCategory]?.value &&
              totals[activeCategory]?.value > 0 && (
                <Pagination
                  total={Math.ceil(
                    Math.min(totals[activeCategory]?.value, 500) / PAGE_SIZE,
                  )}
                  value={page}
                  siblings={3}
                  onChange={(value) => {
                    if (page !== value) {
                      window.scrollTo(0, 0)
                      setCurrentPage(activeCategory, value)
                    }
                  }}
                  size="sm"
                  withEdges
                />
              )}
          </Group>
        )}
      </Tabs>
    </>
  )
}
