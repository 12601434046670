import { RESULT_CATEGORIES } from '@/utils/constants'

const filterCategories = (blackList: string[]) =>
  Object.fromEntries(
    Object.entries(RESULT_CATEGORIES).filter(
      ([value]) => !blackList.includes(value),
    ),
  )

export const HEX_CATEGORIES = filterCategories([
  RESULT_CATEGORIES.INSIGHT,
  RESULT_CATEGORIES.RELATED_IMAGERY,
])

export const MAP_LAYER_OPTIONS = {
  IMAGES: 'images',
  NEWS: 'news',
  SOCIAL: 'social',
  SHIP: 'ship',
  FIRE: 'fire',
}
