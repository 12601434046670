import React, { useMemo } from 'react'
import {
  Button,
  Center,
  Divider,
  Group,
  SegmentedControl,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { formatGeojson } from '@/features/AppLayout/utils'
import { TerrapinDynamicMap } from '@/features/DantiMap'
import type { TerrapinLocation } from '@/hooks/use-terrapin'
import { debug } from '@/utils/debug'
import * as turf from '@turf/turf'
import type { Geometry } from 'geojson'
import {
  getLocationType,
  getLocationValue,
  validateLocation,
} from './TerrapinLocation'
import { TerrapinLocationRow } from './TerrapinLocationRow'

import styles from './terrapin-location-panel.module.css'

enum TerrapinLocationPanelView {
  LIST = 'LIST',
  MAP = 'MAP',
}

interface Actions {
  cancel: () => void
  save: (records: TerrapinLocation[]) => void
}

interface AreasOfInterestPanelProps {
  locations: TerrapinLocation[]
  actions?: Actions
  pointsOnly?: boolean
}

export function TerrapinLocationPanel({
  actions,
  pointsOnly = false, // Bulk AOI does not allow Polygon
  ...props
}: AreasOfInterestPanelProps) {
  const [locations, setLocations] = React.useState(props.locations)
  const [view, setView] = React.useState(TerrapinLocationPanelView.LIST)

  const disableSave = React.useMemo(() => {
    const invalidLocations = locations
      .map(
        (location) =>
          validateLocation(location) || location.name.trim().length === 0,
      )
      .filter(Boolean)
    return invalidLocations.length > 0
  }, [locations])

  const handleAdd = () => {
    const updatedLocations: TerrapinLocation[] = [
      {
        name: '',
        metadata: null,
        filters: [
          {
            type: 'LOCATION',
            value: '',
          },
          {
            type: 'LOCATIONTYPE',
            value: 'latLong',
          },
        ],
      },
      ...locations,
    ]

    setLocations(updatedLocations)
  }

  const handleRemove = (index: number) => {
    const updatedLocations = [...locations]
    updatedLocations.splice(index, 1)

    setLocations(updatedLocations)
  }

  const handleLocationChange = (
    index: number,
    newLocation: TerrapinLocation,
  ) => {
    setLocations((previous) =>
      previous.map((location, locationIndex) =>
        index === locationIndex ? newLocation : location,
      ),
    )
  }

  const TerrapinMapData = useMemo(() => {
    const geojsonList: Geometry[] = []
    locations?.map((aoi) => {
      const type = getLocationType(aoi)
      const value = getLocationValue(aoi)
      const isInvalid = validateLocation(aoi)
      try {
        if (!isInvalid) {
          const location = formatGeojson(type, value)
          geojsonList.push(turf.flip(location))
        }
      } catch (error) {
        debug(error)
      }
    })
    const featureCollection = () => {
      const features = geojsonList.map((geojson) => {
        return turf.feature(geojson)
      })
      return turf.featureCollection(features)
    }

    const data = featureCollection()
    const bbox =
      geojsonList.length > 0
        ? (turf.bbox(featureCollection()) as mapboxgl.LngLatBoundsLike)
        : ([
            [-180, -90],
            [180, 90],
          ] as mapboxgl.LngLatBoundsLike)
    return {
      data,
      bbox,
    }
  }, [locations])

  return (
    <Stack>
      <SegmentedControl
        bg="gray.4"
        size="xs"
        ml="xs"
        value={view}
        onChange={(value) => setView(value as TerrapinLocationPanelView)}
        classNames={{
          root: styles.segmentedControl,
        }}
        data={[
          {
            value: TerrapinLocationPanelView.LIST,
            label: (
              <Center style={{ gap: 8 }}>
                <Icon name="view_agenda" />
                List
              </Center>
            ),
          },
          {
            value: TerrapinLocationPanelView.MAP,
            label: (
              <Center style={{ gap: 8 }}>
                <Icon name="map" />
                Map
              </Center>
            ),
          },
        ]}
      />
      {view === TerrapinLocationPanelView.LIST && (
        <>
          <Text size="sm" pl="xs">
            Verify that the locations from your file are correct.
          </Text>
          <Table withRowBorders={false}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Type</Table.Th>
                <Table.Th>Coordinates</Table.Th>
                <Table.Th>AOI Name (Tag Name)</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={5}>
                  <Divider pb="xs" />
                  <Button
                    variant="subtle"
                    onClick={handleAdd}
                    size="sm"
                    fw={400}
                    leftSection={<Icon name="add_circle_outline" />}
                  >
                    Add a location
                  </Button>
                </Table.Td>
              </Table.Tr>
              {locations.map((location, index) => (
                <TerrapinLocationRow
                  key={location.name}
                  location={location}
                  index={index}
                  pointsOnly={pointsOnly}
                  onChange={handleLocationChange}
                  onRemove={handleRemove}
                />
              ))}
            </Table.Tbody>
          </Table>
        </>
      )}

      {view === TerrapinLocationPanelView.MAP && TerrapinMapData && (
        <TerrapinDynamicMap
          bbox={TerrapinMapData.bbox}
          aois={turf.flip(TerrapinMapData.data)}
        />
      )}

      {actions && (
        <Group justify="space-between" mt="md">
          <Button
            size="xs"
            leftSection={<Icon name="navigate_before" />}
            variant="outline"
            onClick={actions.cancel}
          >
            CANCEL AND GO BACK
          </Button>
          <Button
            size="xs"
            variant="filled"
            disabled={disableSave}
            onClick={() => void actions.save(locations)}
          >
            SAVE CHANGES
          </Button>
        </Group>
      )}
    </Stack>
  )
}
