import { Button, Group, Radio, Stack, Text, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon'
import type { QueryAndDataState } from '@/features/BulkSearch/types'
import { Categories } from '@/features/Filters/components/Categories.tsx'
import { TimePreset } from '@/utils/types/filter-types'

import styles from './query-and-data.module.css'

const TIME_OPTIONS = [
  { label: 'Any time range', value: TimePreset.None },
  { label: 'Past 24 hours', value: TimePreset.Last24Hours },
  { label: 'Past 7 days', value: TimePreset.Last7Days },
  { label: 'Past 30 days', value: TimePreset.Last30Days },
  { label: 'Past 90 days', value: TimePreset.Last90Days },
  { label: 'Custom', value: TimePreset.Custom },
]

export function QueryAndData(props: QueryAndDataState) {
  const {
    searchQuery,
    setSearchQuery,
    savedSearchName,
    setSavedSearchName,
    categories,
    setCategories,
    timePreset,
    setTimePreset,
    customTime,
    setCustomTime,
    resetQueryAndDataState,
  } = props

  return (
    <Stack gap="md">
      <TextInput
        required
        label="Name this Saved Search"
        labelProps={{ className: styles.boldLabel }}
        placeholder="Give the Saved Search folder a name"
        value={savedSearchName}
        onChange={({ currentTarget }) =>
          setSavedSearchName(currentTarget.value)
        }
      />
      <TextInput
        label="Search query"
        labelProps={{ className: styles.boldLabel }}
        placeholder="Add an optional question"
        leftSection={<Icon name="search" />}
        value={searchQuery}
        onChange={({ currentTarget }) => setSearchQuery(currentTarget.value)}
      />
      <Stack gap="xs">
        <Text size="sm" fw={600} lh="h1">
          Edit your search result types
        </Text>
        <Text size="xs" c="dimmed">
          Uncheck a data type to exclude it from your search results
        </Text>
        <Categories value={categories} setter={setCategories} />
      </Stack>
      <Stack gap="xs">
        <Text size="sm" fw={600} lh="h1">
          Select a time range
        </Text>
        <Text size="xs" c="dimmed">
          By default, Danti will give you 1000 of the most relevant results from
          each data type. You can narrow the range with the options below.
        </Text>
        <Group gap="sm" mt="xs">
          {TIME_OPTIONS.map(({ label, value }) => (
            <Radio
              key={label}
              label={label}
              size="xs"
              classNames={{
                label: styles.optionInputLabel,
              }}
              checked={timePreset === value}
              onChange={() => setTimePreset(value)}
            />
          ))}
        </Group>
      </Stack>
      {timePreset === TimePreset.Custom && (
        <DatePickerInput
          required
          type="range"
          label="Custom date range"
          labelProps={{ className: styles.boldLabel }}
          placeholder="Select a date"
          value={customTime}
          onChange={setCustomTime}
          leftSection={<Icon name="calendar_clock" />}
          leftSectionPointerEvents="none"
          rightSection={<Icon name="keyboard_arrow_down" />}
          rightSectionPointerEvents="none"
        />
      )}
      <Button
        variant="transparent"
        className={styles.resetButton}
        onClick={resetQueryAndDataState}
      >
        Reset these filters to default
      </Button>
    </Stack>
  )
}
