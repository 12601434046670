import { Button, Group, Paper, Text } from '@mantine/core'
import { openSaveSearchModal } from '@/features/AppLayout/Modals'
import { FilterToggleButton, useProviders } from '@/features/Filters'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import {
  useCurrentQuery,
  useSavedSearchId,
  useSavedSearchName,
} from '@/stores/queries-store'
import { FEATURE_FLAG_VALUES, SAVED_SEARCH_MODES } from '@/utils/constants'
import { classes } from '@/utils/ui-helpers'

import styles from './queryHeader.module.css'

export function QueryHeader(props: { fixed?: boolean }) {
  const currentQuery = useCurrentQuery()
  const savedSearchId = useSavedSearchId()
  const savedSearchName = useSavedSearchName()
  const { newProviders } = useProviders()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const isSavedSearch = savedSearchId !== ''

  return (
    <Paper
      className={classes(styles.queryContainer, props.fixed && styles.fixed)}
      data-testid="query-header"
    >
      <style>{`body { --danti-head-height: 120px; }`}</style>
      <header className={styles.queryHeader}>
        <Group justify="space-between" gap="sm" w="100%" wrap="nowrap">
          <Text
            title={currentQuery}
            fw="bold"
            data-cy="query-header-query"
            lineClamp={1}
          >
            Q: {isSavedSearch ? `Exploring ${savedSearchName}` : currentQuery}
          </Text>
          <Group wrap="nowrap" gap="xs">
            {!isCommercial && (
              <FilterToggleButton data-cy="query-header-filter-toggle" />
            )}

            <Button
              size="xs"
              onClick={() =>
                openSaveSearchModal({
                  isNew: true,
                  providers: newProviders,
                  params: {
                    query: currentQuery,
                    name: currentQuery,
                    emailNotification: '',
                    mode: SAVED_SEARCH_MODES.search,
                    locations: [],
                  },
                })
              }
              className={styles['menu-hover']}
            >
              SAVE SEARCH
            </Button>
          </Group>
        </Group>
      </header>
    </Paper>
  )
}
