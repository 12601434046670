import React from 'react'
import type { ImageFiltersState } from '@/features/BulkSearch/types.ts'
import { initialUIFilterState } from '@/stores/filters-store/store.ts'

export function useImageFiltersState(): ImageFiltersState {
  const [quality, setQuality] = React.useState(initialUIFilterState.quality)
  const [cloudCover, setCloudCover] = React.useState(
    initialUIFilterState.cloudCover,
  )
  const [angle, setAngle] = React.useState(initialUIFilterState.angle)
  const [orientations, setOrientations] = React.useState(
    initialUIFilterState.orientations,
  )

  function resetImageFiltersState() {
    setQuality(initialUIFilterState.quality)
    setCloudCover(initialUIFilterState.cloudCover)
    setAngle(initialUIFilterState.angle)
    setOrientations(initialUIFilterState.orientations)
  }

  return {
    quality,
    setQuality,
    cloudCover,
    setCloudCover,
    angle,
    setAngle,
    orientations,
    setOrientations,
    resetImageFiltersState,
  }
}
