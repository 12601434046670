import { useState } from 'react'
import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useCollections } from '@/api/collections'

export interface DeleteCollectionModalParams {
  collectionId: string
}

export function DeleteCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId },
}: ContextModalProps<DeleteCollectionModalParams>) {
  const { remove } = useCollections()
  const [isDeleting, setIsDeleting] = useState(false)

  function handleDelete() {
    setIsDeleting(true)

    remove(collectionId)
      .then(() => {
        context.closeModal(modalId)
      })
      .catch(console.error)
      .finally(() => {
        setIsDeleting(false)
      })
  }

  return (
    <>
      <Text>Are you sure you want to delete this saved search?</Text>
      <Group justify="space-between" mt="md">
        <Button
          variant="subtle"
          disabled={isDeleting}
          onClick={() => context.closeModal(modalId)}
        >
          Cancel
        </Button>
        <Button color="red" loading={isDeleting} onClick={handleDelete}>
          Delete
        </Button>
      </Group>
    </>
  )
}
