import * as React from 'react'
import { ActionIcon, em, Tooltip } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import {
  AUTOCOMPLETE_TOOLTIP_PROPS,
  SEARCH_STYLE,
} from '@/features/Search/constants'
import type {
  AutoCompleteValues,
  InputFields,
  LocationField,
} from '@/features/Search/types'
import { classes } from '@/utils/ui-helpers'
import { LocationAutocomplete } from './LocationAutocomplete'
import { SubjectAutocomplete } from './SubjectAutocomplete'

import styles from '@/features/Search/search-bar.module.css'

interface SearchFormProps {
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  searchStyle: SEARCH_STYLE
  inputs: InputFields
  autoComplete: AutoCompleteValues
  locationProps: LocationField
  clearLocationValue: () => void
  handleSubmit: (searchStyle: SEARCH_STYLE) => void
}

export const SearchForm = ({
  handleInlineHotkeyPress,
  searchStyle,
  inputs,
  autoComplete,
  locationProps,
  clearLocationValue,
  handleSubmit,
}: SearchFormProps) => {
  const { locationValue } = inputs
  const { errorMessage } = autoComplete

  const hasError = !!errorMessage
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  const showLocation = React.useMemo(
    () => !isMobile || searchStyle == SEARCH_STYLE.Location,
    [isMobile, searchStyle],
  )

  const canSearch =
    searchStyle == SEARCH_STYLE.General || !!locationValue.trim()
  const processSubmit = (
    event?: React.FormEvent<HTMLFormElement> | KeyboardEvent | React.MouseEvent,
  ) => {
    event?.preventDefault()
    handleSubmit(searchStyle)
  }

  return (
    <form onSubmit={processSubmit} className={styles.searchForm} role="form">
      {showLocation && (
        <>
          <Tooltip
            label={errorMessage}
            opened={hasError}
            {...AUTOCOMPLETE_TOOLTIP_PROPS}
          >
            <LocationAutocomplete
              {...{
                handleInlineHotkeyPress,
                autoComplete,
                locationProps,
                clearLocationValue,
              }}
            />
          </Tooltip>
          {!isMobile && (
            <Icon
              name="add"
              size={24}
              weight={700}
              className={styles.splitSearchDivider}
            />
          )}
        </>
      )}
      <SubjectAutocomplete {...{ handleInlineHotkeyPress, inputs }} />
      <ActionIcon
        type="submit"
        className={classes(
          styles.splitSearchSendButton,
          !canSearch && styles.sendButtonDisabled,
        )}
        onClick={() => handleSubmit(searchStyle)}
        disabled={!canSearch || locationProps.isLoading}
      >
        <Icon name="send" filled />
      </ActionIcon>
    </form>
  )
}
