import React from 'react'
import {
  ActionIcon,
  Button,
  Checkbox,
  FileButton,
  Group,
  InputWrapper,
  Stack,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { CONFIGURATION_TABLE_PROPS as tableProps } from '@/features/AppLayout/Modals/SaveSearchModal'
import {
  PROVIDER_COLUMN_TEMPLATE,
  type ProviderSource,
} from '@/features/Filters'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import type {
  CreateTerrapinParams,
  TerrapinLocation,
} from '@/hooks/use-terrapin'
import { useSetProviders, useTimePresetFilter } from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES, SAVED_SEARCH_MODES } from '@/utils/constants'
import { cloneDeep } from 'lodash'
import Papa from 'papaparse'
import type { TerrapinLocationCsvRecord } from '../TerrapinLocationPanel'
import { ConfigureImageOptions } from './ConfigureImageOptions'
import { ConfigureTimings } from './ConfigureTimings'
import { handleSearchLocationDownload } from './handle-search-location-download'
import { ProviderChecklist } from './ProviderChecklist'

enum TabTitle {
  PROVIDER_SELECTOR = 'provider-selector',
  IMAGE_OPTIONS = 'image-options',
}

const TABS = {
  PROVIDER_SELECTOR: TabTitle.PROVIDER_SELECTOR,
  IMAGE_OPTIONS: TabTitle.IMAGE_OPTIONS,
} as const

function convertToLocation(
  record: TerrapinLocationCsvRecord,
): TerrapinLocation {
  const { location, name, type } = record
  return {
    name,
    metadata: null,
    filters: [
      {
        type: 'LOCATION',
        value: location,
      },
      {
        type: 'LOCATIONTYPE',
        value: type,
      },
    ],
  }
}

function validateCsvRecord(
  result: TerrapinLocationCsvRecord,
  index: number,
): TerrapinLocationCsvRecord | undefined {
  const { location, type } = result
  if (!location || !type) {
    return
  }

  const name =
    (result.name ?? '').trim() === '' ? `Location ${index + 1}` : result.name

  return {
    location,
    type,
    name,
  }
}

export interface SaveSearchPanelProps {
  isNew: boolean
  refId: string | undefined
  isRecurring: boolean
  setIsRecurring: (value: boolean) => void
  backfill: boolean
  setBackfill: (value: boolean) => void
  name: string
  setName: (value: string) => void
  selectedCollectionId: string | undefined
  setSelectedCollectionId: (value: string | undefined) => void
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
  expirationDate: string
  setExpirationDate: (value: string) => void
  locations: TerrapinLocation[]
  setLocations: (locations: TerrapinLocation[]) => void
  isSaving: boolean
  handleSave: (closeModal: () => void) => Promise<void>
  savedConfig: CreateTerrapinParams | undefined
  fileName?: string
  setFileName: (fileName?: string) => void
  onEditLocations: () => void
  onClose: () => void
  providersMap: Map<string, string[]>
  providers: ProviderSource[]
}

export const SaveSearchPanel = ({
  isRecurring,
  setIsRecurring,
  name,
  setName,
  isSaving,
  handleSave,
  expirationDate,
  setExpirationDate,
  emailNotification,
  setEmailNotification,
  backfill,
  setBackfill,
  locations,
  savedConfig,
  setLocations,
  fileName,
  setFileName,
  onEditLocations,
  onClose,
  refId,
  providersMap,
  providers,
}: SaveSearchPanelProps) => {
  const timePreset = useTimePresetFilter()
  const [activeTab, setActiveTab] = React.useState<TabTitle>(
    TABS.PROVIDER_SELECTOR,
  )

  const setProviders = useSetProviders()

  const providerColumns = React.useMemo(() => {
    const columns = cloneDeep(PROVIDER_COLUMN_TEMPLATE)
    const catchAllProviders: ProviderSource[] = []

    providers.forEach((provider) => {
      let isMatched = false
      for (const column of columns) {
        if (column.categories.includes(provider.category)) {
          column.providers.push(provider)
          isMatched = true
        }
      }

      if (!isMatched) {
        catchAllProviders.push(provider)
      }
    })

    const catchAllColumn = columns.find((x) => x.categories[0] === '*')

    if (catchAllColumn) {
      for (const provider of catchAllProviders) {
        catchAllColumn.providers.push(provider)
      }
    }

    return columns
  }, [providers])

  const [isFileInvalid, setIsFileInvalid] = React.useState(false)
  const [file, setFile] = React.useState<File | null>(null)

  const noBackfillTimeSelected = backfill && timePreset === 'None'
  const isFeed = savedConfig?.mode === SAVED_SEARCH_MODES.feed

  const isSaveDisabled = !name || isSaving || noBackfillTimeSelected

  const showDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )
  const enablePlaces = useFeatureFlag(FEATURE_FLAG_VALUES.places)

  React.useEffect(() => {
    if (!file) {
      return
    }
    setFileName(file.name)

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const records = (results.data as TerrapinLocationCsvRecord[]).map(
          validateCsvRecord,
        )

        const invalidRecords = records.filter((x) => x === undefined)

        if (invalidRecords.length > 0) {
          setIsFileInvalid(true)
          setFile(null)
          return
        }

        setLocations(records.filter(Boolean).map(convertToLocation))

        onEditLocations()
      },
      error: (error) => {
        console.error('Error while parsing the file:', error)
      },
    })
  }, [file, setLocations, setIsFileInvalid, onEditLocations, setFileName])

  const handleCloseFile = () => {
    setFile(null)
    setFileName(undefined)
    setLocations([])
    setIsFileInvalid(false)
  }

  const handleProviderUpdate = (label: string, providers: ProviderSource[]) => {
    providersMap.set(
      label,
      providers.map((x) => x.source),
    )
    setProviders([...providersMap.values()].flat())
  }

  return (
    <>
      <Stack gap="md">
        <Checkbox
          checked={isRecurring}
          label="Recurring (check this box if you want this search to run automatically)"
          onChange={(event) => setIsRecurring(event.currentTarget.checked)}
        />

        <TextInput
          value={name}
          label={isFeed ? 'Name this Saved Search' : undefined}
          onChange={(event) => setName(event.target.value)}
          data-cy="save-search-name"
          required
        />

        {isFeed && showDynamicProviders && (
          <Tabs value={activeTab} activateTabWithKeyboard={false}>
            <Tabs.Panel value={TABS.PROVIDER_SELECTOR}>
              <Text size="sm" fw="bold" mb="xs">
                Include the following providers in your search
              </Text>
              <Group
                align="start"
                mt="sm"
                mah={200}
                p="sm"
                style={{
                  overflowY: 'auto',
                  border: '1px solid var(--mantine-color-gray-2)',
                  borderRadius: 6,
                }}
              >
                {providerColumns.map((x) => (
                  <ProviderChecklist
                    key={x.label}
                    label={x.label}
                    onUpdate={handleProviderUpdate}
                    selectedProviders={providersMap.get(x.label) ?? []}
                    providers={x.providers}
                  />
                ))}
              </Group>
              <Button
                size="xs"
                mt="sm"
                onClick={() => setActiveTab(TABS.IMAGE_OPTIONS)}
              >
                Image options
              </Button>
            </Tabs.Panel>
            <Tabs.Panel value="image-options">
              <Button
                variant="transparent"
                p={0}
                c="gray"
                leftSection={<Icon name="arrow_circle_left" />}
                onClick={() => setActiveTab(TABS.PROVIDER_SELECTOR)}
                title="Back"
              >
                Back to providers
              </Button>
              <ConfigureImageOptions tableProps={tableProps} />
            </Tabs.Panel>
          </Tabs>
        )}
        {isFeed && !showDynamicProviders && (
          <ConfigureImageOptions tableProps={tableProps} />
        )}
        {isRecurring && (
          <ConfigureTimings
            savedConfig={savedConfig}
            tableProps={tableProps}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
            emailNotification={emailNotification}
            setEmailNotification={setEmailNotification}
            backfill={backfill}
            setBackfill={setBackfill}
          />
        )}
        {enablePlaces && isFeed && (
          <Stack gap="sm">
            <Group align="center" gap={0}>
              {locations.length > 0 ? (
                <Button
                  size="xs"
                  style={{ alignSelf: 'start' }}
                  onClick={() => onEditLocations()}
                >
                  EDIT LOCATIONS
                </Button>
              ) : (
                <InputWrapper
                  error={
                    isFileInvalid
                      ? 'Invalid file format, please try again'
                      : undefined
                  }
                  styles={{ error: { marginTop: 10 } }}
                >
                  <FileButton onChange={setFile} accept="text/csv">
                    {(props) => (
                      <Button
                        size="xs"
                        style={{ alignSelf: 'start' }}
                        leftSection={
                          <Icon name="add_circle_outline" size="xs" filled />
                        }
                        {...props}
                      >
                        UPLOAD LOCATIONS
                      </Button>
                    )}
                  </FileButton>
                </InputWrapper>
              )}
              {refId && locations.length > 0 && (
                <Button
                  size="xs"
                  style={{ alignSelf: 'start', marginLeft: 10 }}
                  onClick={() => {
                    void handleSearchLocationDownload(refId)
                  }}
                >
                  DOWNLOAD LOCATIONS
                </Button>
              )}
              {fileName && (
                <Group
                  align="center"
                  gap="sm"
                  mt={locations.length > 0 ? 0 : -20}
                  ml={locations.length > 0 ? 10 : 0}
                >
                  <Text size="sm">{fileName}</Text>
                  <ActionIcon
                    variant="white"
                    color="black"
                    onClick={() => handleCloseFile()}
                  >
                    <Icon name="highlight_off" />
                  </ActionIcon>
                </Group>
              )}
            </Group>
            <Text size="xs">
              Upload a spreadsheet of locations in latitude/longitude, polygon
              or MGRS format.
            </Text>
            <Text size="xs" mt={-8}>
              Use template provided{' '}
              <a
                style={{ color: '#0091EA' }}
                href="https://imagery-1724-stage.s3.us-west-2.amazonaws.com/templates/areas-of-interests-template.csv"
              >
                here
              </a>
            </Text>
          </Stack>
        )}
      </Stack>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" size="xs" color="#000000DE" onClick={onClose}>
          CANCEL
        </Button>

        <Button
          size="xs"
          loading={isSaving}
          onClick={() => void handleSave(onClose)}
          disabled={isSaveDisabled}
        >
          SAVE
        </Button>
      </Group>
    </>
  )
}
