import { Box, Stack, Text } from '@mantine/core'
import { usePaginatedResults, useSearchStatus } from '@/api/results'
import type { Results } from '@/api/results/types'
import {
  ResultsTimelineBarGraph,
  ResultsTimelineLoading,
} from '@/features/ResultsTimeline/components'
import { useHistogramData } from '@/features/ResultsTimeline/hooks'
import { formatDateRange } from '@/features/ResultsTimeline/utils'
import type { FC } from 'react'

type ResultsTimelineProps = {
  width: number
  isSmall: boolean
  type?: keyof Partial<Results>
}

export const ResultsTimeline: FC<ResultsTimelineProps> = ({
  width,
  isSmall,
  type,
}) => {
  const searchStatus = useSearchStatus()
  const isReadyForInteraction = ['success', 'idle'].includes(searchStatus || '')
  const results = usePaginatedResults()
  const effectiveResults = type
    ? { [type]: results.results[type] }
    : results.results
  const { data, categories, dateRange } = useHistogramData(effectiveResults)

  if (width === 0) {
    return null
  }

  return (
    <Stack
      w={width}
      style={{
        position: 'relative',
        paddingTop: 16,
        paddingBottom: 4,
        backgroundColor: isReadyForInteraction
          ? 'white'
          : 'var(--mantine-color-gray-1)',
      }}
    >
      <Box style={{ position: 'relative' }}>
        {isReadyForInteraction ? (
          <Stack w={width} gap="xs">
            <Text size="sm" c="dimmed" ta="left">
              {formatDateRange(dateRange.min, dateRange.max)}
            </Text>
            <ResultsTimelineBarGraph
              small={isSmall}
              categories={categories}
              data={data}
              dateRange={dateRange}
            />
          </Stack>
        ) : (
          <Stack w={width} gap="xs">
            <Text size="sm" c="dimmed" ta="left" h="1rem" />
            <ResultsTimelineLoading
              width={width}
              small={isSmall}
              numBars={21}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  )
}
