import { Divider, Group, Menu, Stack, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import {
  ActionsMenu,
  SearchInput,
  SortToggleButton,
  TypeControl,
} from '@/features/Collections/components'
import { useCollectionsHeader } from '@/features/Collections/hooks'
import type { ViewType } from '@/features/Collections/types'
import { AdvancedSearch } from '@/features/Search/views/SearchBar/AdvancedSearch'
import { useCollectionsState } from '@/stores/collections'
import { ICONS } from '@/utils/constants'

export interface CollectionsHeaderProps {
  viewType: ViewType
  setViewType: (value: ViewType) => void
}

export function CollectionsHeader({
  viewType,
  setViewType,
}: CollectionsHeaderProps) {
  const state = useCollectionsState()
  const headerData = useCollectionsHeader()
  const [showAdvancedSearch, { toggle: toggleAdvancedSearch }] =
    useDisclosure(false)

  const menuItems = [
    {
      icon: <Icon name={ICONS.dashboard} filled />,
      label: 'New saved search folder',
      onClick: headerData.handleNewCollectionClick,
    },
    {
      icon: <Icon name={ICONS.update} />,
      label: 'Create a new AOI saved search',
      onClick: toggleAdvancedSearch,
    },
    {
      icon: <Icon name={ICONS.fileUpload} />,
      label: 'Upload a file',
      onClick: headerData.handleUploadFileClick,
    },
  ]
  const { searchTerm, setSearchTerm } = headerData

  return (
    <>
      <Stack w="100%" gap={12}>
        <Title c="gray.8" order={1}>
          Saved Searches
        </Title>
        <Group gap="sm">
          <SearchInput {...{ searchTerm, setSearchTerm }} />
          <TypeControl {...{ viewType, setViewType }} />
          <ActionsMenu>
            {menuItems.map(({ icon, label, onClick }) => (
              <Menu.Item key={label} leftSection={icon} onClick={onClick}>
                {label}
              </Menu.Item>
            ))}
          </ActionsMenu>
          <SortToggleButton sort={state.sort} onSortChange={state.setSort} />
        </Group>
        <Divider />
      </Stack>
      <AdvancedSearch
        opened={showAdvancedSearch}
        handleClose={toggleAdvancedSearch}
      />
    </>
  )
}
