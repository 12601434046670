import {
  ActionIcon,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
} from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { useResultMenuActions } from '@/features/ResultActions/hooks'
import type { ActionMenuOption } from '@/features/ResultActions/types'
import { classes } from '@/utils/ui-helpers'

import styles from './result-actions.module.css'

export interface ResultActionsProps<Type> {
  hideActions: ActionMenuOption[]
  result: Type
  className?: string
  iconClassName?: string
  item?: CollectionItem
}

export function ResultActions<Type>({
  hideActions,
  result,
  item,
  className,
  iconClassName,
}: ResultActionsProps<Type>) {
  const actions = useResultMenuActions({ result, hideActions, item })

  return (
    <Menu trigger="hover" shadow="md">
      <MenuTarget data-cy="resultActionMenu">
        <ActionIcon
          variant="white"
          className={classes(iconClassName ?? styles.clickable, className)}
          onClick={(event) => event.stopPropagation()}
        >
          <Icon className={styles.icon} name="more_vert" size={24} />
        </ActionIcon>
      </MenuTarget>
      <MenuDropdown>
        {actions.map(({ label, icon, onClick, ...passProps }) => (
          <MenuItem
            key={label}
            {...passProps}
            leftSection={<Icon name={icon} />}
            onClick={onClick}
            p="xs"
            className={classes(
              styles.menuItem,
              passProps.disabled ? styles.disabled : '',
            )}
          >
            {label}
          </MenuItem>
        ))}
      </MenuDropdown>
    </Menu>
  )
}
