import type { CollectionSortType } from '@/api/collections'
import { CategoryTypes } from '@/utils/types/raw-result-types.ts'

export const SAVED_SEARCH_DISABLED_MESSAGE =
  'Filtering is unavailable while exploring saved search results'

export const COLLECTION_REF_TYPES = {
  bulkSearch: 'TERRAPIN-CFG-BULK',
}

export const VIEW_TYPES = {
  grid: 'grid',
  list: 'list',
}

export const SORT_TYPES = {
  date: 'date',
  name: 'name',
  kind: 'kind',
}

export const SORT_FIELDS = {
  [SORT_TYPES.date]: [
    { value: 'date-desc', label: 'Sort ↓' },
    { value: 'date-asc', label: 'Sort ↑' },
  ],
  [SORT_TYPES.name]: [
    { value: 'alpha-desc', label: 'Sort ↓' },
    { value: 'alpha-asc', label: 'Sort ↑' },
  ],
  // Not currently supported by backend filtering
  [SORT_TYPES.kind]: [
    { value: 'alpha-desc', label: 'Sort ↓' },
    { value: 'alpha-asc', label: 'Sort ↑' },
  ],
} as Record<
  ValueOf<typeof SORT_TYPES>,
  { value: CollectionSortType; label: string }[]
>

export const CATEGORY_FILTER_ITEMS = [
  { label: 'Images', value: CategoryTypes.IMAGE },
  { label: 'News', value: CategoryTypes.PUBLICATION },
  { label: 'Social', value: CategoryTypes.SOCIAL_MEDIA },
  { label: 'Ships', value: CategoryTypes.SHIP },
  { label: 'Fires', value: CategoryTypes.FIRE },
  { label: 'Collection', value: CategoryTypes.COLLECTION },
  // { label: 'FEMA', value: CategoryTypes.FEMA },
  // { label: 'Earthquake', value: CategoryTypes.EARTHQUAKE },
  // { label: 'Analytic', value: CategoryTypes.ANALYTIC },
  // { label: 'File', value: CategoryTypes.FILE },
]
