import { emitter, EVENTS } from '@/events'
import type { QueriesState } from '@/stores/queries-store/types'
import { createStore } from '@/utils/create-store'
import type { SearchApiFilterValue } from '@/utils/types/filter-types'
import {
  QuerySourceKeys,
  QuerySourceState,
  type QueryStatus,
} from '@/utils/types/message-types'

export const initialState: Partial<QueriesState> = {
  currentQuery: '',
  currentQueryFilters: [],
  currentQueryStart: null,
  rootQueryId: null,
  insightFeedbackSubmitted: false,
  postSortPreference: 'score',
  queryStatus: Object.fromEntries(
    Object.values(QuerySourceKeys).map((k) => [
      k,
      QuerySourceState.NOT_STARTED,
    ]),
  ) as QueryStatus,
  pages: {},
  savedSearchId: '',
  savedSearchName: '',
  searchMetadata: null,
  sortPreference: 'authoredOn',
  storedLocation: null,
  totalProcessedResults: 0,
}

export const useQueryStore = createStore<QueriesState>({
  storageKey: 'QueryStore',
  version: 0.03,
  initialState: initialState,
  actions: (set, get): Partial<QueriesState> => ({
    clear: () => {
      set({
        currentQuery: '',
        currentQueryStart: null,
        queryStatus: initialState.queryStatus,
        pages: {},
        savedSearchId: '',
        savedSearchName: '',
        searchMetadata: null,
        totalProcessedResults: 0,
      })
      emitter.emit(EVENTS.queriesStore.searchMetadataUpdated, {
        searchMetadata: null,
      })
    },
    setInsightFeedbackSubmitted: (submitted: boolean) =>
      set({ insightFeedbackSubmitted: submitted }),
    setPage: (type: string, page: number) =>
      set((state) => ({ pages: { ...state.pages, [type]: page } })),
    setSavedSearchId: (id: string) => set({ savedSearchId: id }),
    setSavedSearchName: (name: string) => set({ savedSearchName: name }),
    setQuery: (query: string) =>
      set({
        currentQuery: query,
        queryStatus: initialState.queryStatus,
        pages: {},
      }),
    setQueryFilters: (apiFilters: SearchApiFilterValue[]) => {
      set({
        currentQueryFilters: apiFilters,
        pages: {},
      })
      emitter.emit(EVENTS.queriesStore.currentQueryFiltersUpdated, {
        currentQueryFilters: apiFilters,
      })
    },
    setQueryStarted: () =>
      set({ currentQueryStart: new Date(), totalProcessedResults: 0 }),
    setRootQueryId: (rootQueryId: string | null) => set({ rootQueryId }),
    setSearchMetadata: (lastSearchMetadata) => {
      set({ searchMetadata: lastSearchMetadata })
      emitter.emit(EVENTS.queriesStore.searchMetadataUpdated, {
        searchMetadata: lastSearchMetadata,
      })
    },
    setPostSortPreference: (postSortPreference) =>
      set({
        postSortPreference,
        pages: {},
      }),
    setSortPreference: (sortPreference) =>
      set({
        sortPreference,
        pages: {},
      }),
    setStatus: (key: QuerySourceKeys, state: QuerySourceState) => {
      const currentStatus = get().queryStatus as QueryStatus
      // Load query given status only if query is not already in progress
      if (
        ![QuerySourceState.COMPLETED, QuerySourceState.ERROR].includes(
          currentStatus[key],
        )
      ) {
        set({ queryStatus: { ...currentStatus, [key]: state } })
      }
    },
    setStoredLocation: (location: QueriesState['storedLocation']) =>
      set({ storedLocation: location }),

    setStoredSplitSearch: (
      storedSplitSearch: QueriesState['storedSplitSearch'],
    ) => set({ storedSplitSearch }),

    setTotalProcessedResults: (count: number) =>
      set({ totalProcessedResults: count }),
  }),
})
