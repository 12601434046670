import { Box, Button, Grid, Stack, Text } from '@mantine/core'
import type { ImageFiltersState } from '@/features/BulkSearch/types'
import {
  CameraAngle,
  CloudCover,
  Orientation,
  Quality,
} from '@/features/Filters'
import type { PropsWithChildren } from 'react'

const FilterSection = ({
  children,
  testId,
}: PropsWithChildren<{ testId: string }>) => (
  <Box
    p="sm"
    h="100%"
    id={`${testId.toLowerCase()}-filters`}
    style={{
      border: '1px solid var(--mantine-color-gray-3)',
      borderRadius: 'var(--mantine-radius-lg)',
    }}
  >
    {children}
  </Box>
)

export function ImageFilters(props: ImageFiltersState) {
  const {
    quality,
    setQuality,
    cloudCover,
    setCloudCover,
    angle,
    setAngle,
    orientations,
    setOrientations,
    resetImageFiltersState,
  } = props

  return (
    <Box mt="xs" mb="md">
      <Text fz="sm" fw="bold" mb={0}>
        Edit your image search options
      </Text>
      <Text fz="sm">
        Use the controls below to customize your image search results
      </Text>
      <Grid mt="sm">
        <Grid.Col span={5}>
          <FilterSection testId="quality">
            <Quality value={quality} setter={setQuality} />
          </FilterSection>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack h="100%" gap="md">
            <FilterSection testId="Cloud Cover">
              <CloudCover value={cloudCover} setter={setCloudCover} />
            </FilterSection>
            <FilterSection testId="Camera Angle">
              <CameraAngle value={angle} setter={setAngle} />
            </FilterSection>
          </Stack>
        </Grid.Col>
        <Grid.Col span={3}>
          <FilterSection testId="orientation">
            <Orientation value={orientations} setter={setOrientations} />
          </FilterSection>
        </Grid.Col>
      </Grid>
      <Button
        mt="sm"
        p={0}
        variant="transparent"
        fw="normal"
        onClick={resetImageFiltersState}
      >
        Reset these filters to default
      </Button>
    </Box>
  )
}
