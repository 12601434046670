import { Grid } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types.ts'
import { CollectionItemGridResult } from './CollectionItemGridResult'

export interface CollectionDetailGridProps {
  items: CollectionItem[]
  onClick: (id: string) => void
}

export function CollectionDetailGrid({ items }: CollectionDetailGridProps) {
  const footerHeight = 100
  const padding = 30

  return (
    <Grid
      columns={12}
      gutter="sm"
      mt="lg"
      style={{ overflow: 'initial' }}
      pb={footerHeight + padding}
    >
      {items.map((item) => (
        <Grid.Col key={item.id} span={{ base: 6, xs: 4, sm: 4, md: 4, lg: 3 }}>
          <CollectionItemGridResult item={item} key={item.id} />
        </Grid.Col>
      ))}
    </Grid>
  )
}
