import { Stack, Text, Title } from '@mantine/core'

type StatusBlockProps = {
  title: string
  icon: React.ReactNode
  description: React.ReactNode | string
}

export function StatusBlock({ title, icon, description }: StatusBlockProps) {
  return (
    <Stack
      align="center"
      justify="center"
      gap="sm"
      p="xl"
      mih="450"
      style={{
        border: '1px solid var(--mantine-color-gray-3)',
        borderRadius: 'var(--mantine-radius-lg)',
      }}
    >
      <Title fw="normal">{title}</Title>
      {icon}
      <Text ta="center">{description}</Text>
    </Stack>
  )
}
