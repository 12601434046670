import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, TextInput } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import {
  useSetStoredLocation,
  useSetStoredSplitSearch,
} from '@/stores/queries-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { classes } from '@/utils/ui-helpers.tsx'
import { AdvancedSearch } from './AdvancedSearch'

import styles from '@/features/Search/search-bar.module.css'

interface UnifiedSearchProps {
  value: string
  style?: React.CSSProperties
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  doTextSearch: (value: string) => void
}

export const UnifiedSearch = forwardRef(
  (
    { value, doTextSearch, handleInlineHotkeyPress }: UnifiedSearchProps,
    ref,
  ) => {
    const setStoredLocation = useSetStoredLocation()
    const setStoredSplitSearch = useSetStoredSplitSearch()
    const navigate = useNavigate()
    const unifiedRef = useRef<HTMLInputElement>(null)
    const [unifiedValue, setUnifiedValue] = useState(value)
    const [showAdvancedSearch, { toggle: toggleAdvancedSearch }] =
      useDisclosure(false)

    const isAdvancedPoiSearchEnabled = useFeatureFlag(
      FEATURE_FLAG_VALUES.advancedPoiSearch,
    )

    const handleSetQuery = (value: string) => {
      if (!value) {
        return
      }

      // Sync state with split search
      const components = value.split(' - ')
      if (components.length > 0) {
        if (components.length === 1) {
          setStoredSplitSearch({
            location: '',
            subject: components[0],
          })
        } else if (components.length === 2) {
          setStoredSplitSearch({
            location: components[0],
            subject: components[1],
          })
        }
      }

      setStoredLocation(null)
      void doTextSearch(value)
      unifiedRef.current?.blur()

      const params = new URLSearchParams({ search: value })
      const pathname = window.location.pathname.toLowerCase()

      if (pathname.startsWith('/map')) {
        navigate(`/map?${params.toString()}`)
      } else {
        navigate(`/overview?${params.toString()}`)
      }
    }

    const processSubmit = (
      event?: React.FormEvent<HTMLFormElement> | KeyboardEvent,
    ) => {
      event?.preventDefault()
      if (unifiedValue.trim().length > 0) {
        handleSetQuery(unifiedValue)
      }
    }
    const focus = () => unifiedRef.current?.focus()

    useImperativeHandle(ref, () => ({
      processSubmit,
      focus,
    }))

    const canSubmit = unifiedValue.trim()

    return (
      <>
        <form onSubmit={processSubmit} className={styles.unifiedSearchForm}>
          <TextInput
            size="md"
            w="100%"
            classNames={{
              input: styles.searchInput,
            }}
            leftSection={
              isAdvancedPoiSearchEnabled ? (
                <ActionIcon
                  variant="transparent"
                  radius="xl"
                  onClick={toggleAdvancedSearch}
                >
                  <Icon name="content_paste_go" />
                </ActionIcon>
              ) : (
                <Icon name="search" />
              )
            }
            rightSection={
              <ActionIcon
                type="submit"
                variant="filled"
                size="md"
                disabled={!canSubmit}
                className={classes(
                  styles.sendButton,
                  !canSubmit && styles.sendButtonDisabled,
                )}
              >
                <Icon name="arrow_forward" />
              </ActionIcon>
            }
            value={unifiedValue}
            onChange={(event) => setUnifiedValue(event.currentTarget.value)}
            onKeyDown={handleInlineHotkeyPress}
            placeholder="Ask Danti a question about a location"
            ref={unifiedRef}
          />
        </form>
        <AdvancedSearch
          opened={showAdvancedSearch}
          handleClose={toggleAdvancedSearch}
        />
      </>
    )
  },
)

UnifiedSearch.displayName = 'UnifiedSearch'
