import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ActionIcon,
  Button,
  Group,
  Pagination,
  SimpleGrid,
  Stack,
  Title,
} from '@mantine/core'
import { PAGE_SIZE, usePaginatedResults, useResultTotals } from '@/api/results'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import { Icon } from '@/components/lib/Icon'
import { Skeleton } from '@/components/lib/Skeleton'
import { QueryHeader } from '@/features/AppLayout'
import {
  useBackNavigation,
  useNavigateToResultCallback,
} from '@/features/AppRouting/hooks'
// import { ResultsDateFilter } from '@/features/Filters/components'
import { ResultActions } from '@/features/ResultActions'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import {
  FireReferenceCard,
  ImageReferenceCard,
  NewsReferenceCard,
  ShipReferenceCard,
  SocialMediaReferenceCard,
} from '@/features/ResultCards'
import { ResultsTimeline } from '@/features/ResultsTimeline'
import { ROUTE_CATEGORIES, TYPE_CONFIG } from '@/pages/CategoryPage/constants'
import { SortSelect } from '@/pages/CategoryPage/SortSelect'
import {
  usePostSortPreference,
  useSetCategoryPage,
  useSetPostSortPreference,
  useSetSortPreference,
  useSortPreference,
} from '@/stores/queries-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import type {
  FireResult,
  ImageryResult,
  PublicationResult,
  ResultWithId,
  ShipResult,
  SocialMediaResult,
} from '@/utils/types/result-types'

import style from './category-page.module.css'

export function CategoryPage() {
  const location = useLocation()
  const category = ROUTE_CATEGORIES[location.pathname]
  const allResults = useTimelineFilteredResults().categorized
  const { pages, queries } = usePaginatedResults()
  const page = pages[category] || 1

  const results = allResults[category as keyof typeof allResults]
  const query = queries[category]
  const { title } = TYPE_CONFIG[category]
  const sortPreference = useSortPreference()
  const setSortPreference = useSetSortPreference()
  const postSortPreference = usePostSortPreference()
  const setCurrentPage = useSetCategoryPage()
  const setPostSortPreference = useSetPostSortPreference()
  const totals = useResultTotals()
  const isLoading = query?.isLoading

  const renderCard = (result: ResultWithId) => {
    switch (category) {
      case SEARCH_CATEGORIES.FIRE:
        return <FireReferenceCard result={result as FireResult} />
      case SEARCH_CATEGORIES.SHIP:
        return <ShipReferenceCard result={result as ShipResult} />
      case SEARCH_CATEGORIES.IMAGE:
        return <ImageReferenceCard result={result as ImageryResult} />
      case SEARCH_CATEGORIES.PUBLICATION:
        return <NewsReferenceCard result={result as PublicationResult} />
      case SEARCH_CATEGORIES.SOCIAL_MEDIA:
        return <SocialMediaReferenceCard result={result as SocialMediaResult} />
    }
  }
  const isPost = [
    SEARCH_CATEGORIES.PUBLICATION,
    SEARCH_CATEGORIES.SOCIAL_MEDIA,
  ].includes(category)

  const navigateToResultCallback = useNavigateToResultCallback()
  const goBack = useBackNavigation()

  const skeletonItems = Array.from({ length: PAGE_SIZE }).map(() =>
    crypto.randomUUID(),
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <QueryHeader fixed />
      <div className={style.categoryPage}>
        <Stack gap={0}>
          <Stack gap={0}>
            <ActionIcon
              mt="lg"
              title="Go back"
              variant="transparent"
              onClick={goBack}
            >
              <Icon name="arrow_circle_left" size="xl" />
            </ActionIcon>
          </Stack>
          <Group mb="sm" gap={6}>
            <Title order={2} size={48}>
              {title}
            </Title>
          </Group>
          <Group mb="md" gap={6} justify="space-between" align="end">
            {isLoading ? (
              <Skeleton className={style.timelineSkeleton} />
            ) : (
              <>
                <ResultsTimeline width={700} isSmall type={category} />
                <Group mb="md">
                  {/*<ResultsDateFilter />*/}
                  <SortSelect
                    {...{
                      sortPreference: isPost
                        ? postSortPreference
                        : sortPreference,
                      setSortPreference: isPost
                        ? setPostSortPreference
                        : setSortPreference,
                    }}
                  />
                </Group>
              </>
            )}
          </Group>
        </Stack>
        <SimpleGrid
          cols={{ xs: 2, sm: 3, md: 4, lg: 4, xl: 5 }}
          className={style.grid}
          verticalSpacing="md"
        >
          {isLoading
            ? skeletonItems.map((id) => (
                <Skeleton key={id} className={style.cardSkeleton} />
              ))
            : results.slice(0, PAGE_SIZE).map((result) => (
                <div
                  key={result.id}
                  onClick={navigateToResultCallback(result)}
                  className={style.cardContainer}
                >
                  {renderCard(result)}
                  <ResultActions
                    result={result}
                    className={style.reveal}
                    iconClassName={style.clickable}
                    hideActions={HIDE_ACTIONS_BY_CATEGORY[category]}
                  />
                </div>
              ))}
        </SimpleGrid>
        {isLoading ? (
          <Skeleton className={style.paginationSkeleton} />
        ) : (
          results.length > 0 && (
            <Group mt="lg" mb="lg" gap={6} justify="space-between">
              {totals[category]?.value && totals[category]?.value > 0 && (
                <Pagination
                  total={Math.ceil(
                    Math.min(totals[category]?.value, 220) / PAGE_SIZE,
                  )}
                  value={page}
                  siblings={3}
                  onChange={(value) => {
                    if (page !== value) {
                      window.scrollTo(0, 0)
                      setCurrentPage(category, value)
                    }
                  }}
                  withEdges
                />
              )}
              <Button
                variant="light"
                color="blue"
                size="xs"
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
                className={style.backToTopButton}
              >
                Back to top
              </Button>
            </Group>
          )
        )}
      </div>
    </div>
  )
}
