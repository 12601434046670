import React from 'react'
import { Box, Image } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ResultImage } from '@/features/ResultImage'
import { ICONS } from '@/utils/constants'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'

import style from './result-image.module.css'

export interface CollectionDetailResultIconProps {
  result: ResultWithId
}

export const CollectionDetailResultIcon = ({
  result,
}: CollectionDetailResultIconProps) => {
  const [showImage, setShowImage] = React.useState(
    Boolean(result?.properties?.src_image_url ?? result?.thumbnail),
  )

  const icons = {
    dashboard: <Icon name={ICONS.dashboard} filled />,
    fire: <Icon name={ICONS.localFireDepartment} />,
    anchor: <Icon name={ICONS.anchor} />,
    folder: <Icon name={ICONS.folder} />,
  }

  if (isImageryResult(result)) {
    return <ResultImage item={result} rootClassName={style.resultImage} />
  }

  if (isSocialMediaResult(result)) {
    return showImage ? (
      <Image
        src={result.properties.src_image_url ?? result.thumbnail}
        onError={() => setShowImage(false)}
        w={24}
        h={24}
        fit="fill"
      />
    ) : (
      icons.dashboard
    )
  }

  const dataResultLayout = { style: { borderRadius: '50%' }, pt: 3, px: 4 }

  if (isFireResult(result)) {
    return (
      <Box bg="red.5" {...dataResultLayout}>
        {icons.fire}
      </Box>
    )
  }

  if (isShipResult(result)) {
    return (
      <Box bg="#00A4B0" {...dataResultLayout}>
        {icons.anchor}
      </Box>
    )
  }

  return icons.dashboard
}
