import { DATA_METADATA_CATEGORIES } from '@/api/data-metadata/constants.ts'
import { useTerrapin } from '@/hooks/use-terrapin.tsx'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters.ts'
import { useImageFiltersState } from './use-image-filters-state'
import { useImageProvidersState } from './use-image-providers-state'
import { useLocationsState } from './use-locations-state'
import { useQueryAndDataState } from './use-query-and-data-state'

// Establishes a single instance of the stores to be used in each bulk search modal instance.
// The root of the BulkSearchModal component should call either this hook or the individual
// hooks within to ensure only one instance of the store exists per modal.
// Values from this hook should be passed down to child components as props.
export function useBulkSearch() {
  const locationsState = useLocationsState()
  const queryAndDataState = useQueryAndDataState()
  const imageProvidersState = useImageProvidersState()
  const imageFiltersState = useImageFiltersState()

  const { createBulkAoiSearch } = useTerrapin()

  function resetAll() {
    locationsState.resetLocationsState()
    queryAndDataState.resetQueryAndDataState()
    imageProvidersState.resetImageProvidersState()
    imageFiltersState.resetImageFiltersState()
  }

  function assemblePayload() {
    const { savedSearchName, searchQuery, categories, timePreset, customTime } =
      queryAndDataState
    const { providers, sources, platforms, sensors } = imageProvidersState
    const { quality, cloudCover, orientations, angle } = imageFiltersState

    const hasImagery =
      categories.length === 0 ||
      categories.includes(DATA_METADATA_CATEGORIES.IMAGE)

    // Assemble existing values and ignore empty/undefined
    const filters = makeApiFilters({
      categories,
      timePreset,
      customTimeStart: customTime?.[0],
      customTimeEnd: customTime?.[1],
      ...(hasImagery && {
        providers: [...providers],
        sources: [...sources],
        platforms: [...platforms],
        sensors: [...sensors],
        quality,
        cloudCover,
        orientations,
        angle,
      }),
    })

    return {
      name: savedSearchName,
      ...(searchQuery && {
        query: searchQuery,
      }),
      locations: locationsState.locationsList.map((location) => ({
        name: location.name,
        filters: location.filters,
        metadata: location.metadata,
      })),
      filters,
    }
  }

  function saveBulkAoiSearch() {
    const payload = assemblePayload()

    return createBulkAoiSearch(payload)
  }

  return {
    locationsState,
    queryAndDataState,
    imageProvidersState,
    imageFiltersState,
    saveBulkAoiSearch,
    resetAll,
  }
}
