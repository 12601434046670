import type { SearchCategory } from '@/api/results'
import type { BulkSearchGeolocationWithId } from '@/api/search/types.ts'
import type { DateValue, TimePreset } from '@/utils/types/filter-types.ts'

export interface OrientationSegment {
  start: number
  end: number
}

export const LocationStatuses = {
  FRESH: 'fresh',
  LOADING: 'loading',
  VALIDATED: 'validated',
} as const

export type LocationStatus = ValueOf<typeof LocationStatuses>

export type LocationsListSetter = React.Dispatch<
  React.SetStateAction<BulkSearchGeolocationWithId[]>
>

export interface LocationsState {
  locationsInputValue: string
  setLocationsInputValue: (inputValue: string) => void
  locationsFile: File | null
  setLocationsFile: (file: File | null) => void
  locationsList: BulkSearchGeolocationWithId[]
  setLocationsList: LocationsListSetter
  locationsError: string | null
  setLocationsError: (error: string | null) => void
  status: LocationStatus
  setStatus: (status: LocationStatus) => void
  resetLocationsState: () => void
}

export interface QueryAndDataState {
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  savedSearchName: string
  setSavedSearchName: (savedSearchName: string) => void
  categories: SearchCategory[]
  setCategories: (categories: SearchCategory[]) => void
  timePreset: TimePreset
  setTimePreset: (timePreset: TimePreset) => void
  customTime: [DateValue, DateValue]
  setCustomTime: ([start, end]: [DateValue, DateValue]) => void
  resetQueryAndDataState: () => void
}

export interface ImageProvidersState {
  providers: Set<string>
  setProviders: (providers: Set<string>) => void
  sources: Set<string>
  setSources: (sources: Set<string>) => void
  platforms: Set<string>
  setPlatforms: (platforms: Set<string>) => void
  sensors: Set<string>
  setSensors: (sensors: Set<string>) => void
  imageProvidersSetter: (value: { [category: string]: Set<string> }) => void
  resetImageProvidersState: () => void
}

export interface ImageFiltersState {
  quality: string
  setQuality: (quality: string) => void
  cloudCover: number
  setCloudCover: (cloudCover: number) => void
  angle: string
  setAngle: (angle: string) => void
  orientations: OrientationSegment[]
  setOrientations: (orientations: OrientationSegment[]) => void
  resetImageFiltersState: () => void
}

export interface UseBulkSearchHookResponse {
  locationsState: LocationsState
  queryAndDataState: QueryAndDataState
  imageProvidersState: ImageProvidersState
  imageFiltersState: ImageFiltersState
}
