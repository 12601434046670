import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Burger, Group } from '@mantine/core'
import { useAddToCollection, useCollections } from '@/api/collections'
import { useDataMetadata } from '@/api/data-metadata/use-data-metadata.ts'
import { DantiWordMark } from '@/components/lib/DantiWordMark'
import { NAV_ITEMS } from '@/features/AppLayout/constants'
import { useOverviewUrl } from '@/features/AppRouting/hooks'
import { SearchBar } from '@/features/Search'
import { useClearAll } from '@/hooks/use-clear-all'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { HeaderLink, HeaderLinkHistory, HeaderUserMenu } from './components'

import searchStyles from '@/features/Search/search-bar.module.css'

export interface HeaderAuthenticatedProps {
  opened: boolean
  toggle: () => void
}

export function HeaderAuthenticated({
  opened,
  toggle,
}: HeaderAuthenticatedProps) {
  useDataMetadata()
  useCollections()
  useAddToCollection()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const clearAll = useClearAll()
  const overviewUrl = useOverviewUrl()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  // TODO: re-enable when feature flags are working
  // const isTimelineEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.timeline)
  const isTimelineEnabled = false

  const isMap = pathname === '/map'

  return (
    <Box w="100%">
      <Group justify="space-between" w="100%">
        <Group h="100%" gap="xs" align="center" visibleFrom="sm">
          <DantiWordMark
            height={29}
            style={{
              marginRight: '1rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              clearAll()
              navigate('/')
            }}
            color="white"
          />
          {NAV_ITEMS.filter(
            (navItem) => !isCommercial || navItem.id !== 'map',
          ).map(({ id, path, label, icon }) => {
            switch (id) {
              case 'explore':
                return (
                  <HeaderLink
                    to={overviewUrl}
                    key={id}
                    title={label}
                    icon={icon}
                  />
                )
              case 'history':
                return <HeaderLinkHistory key={id} />
              case 'timeline':
                return isTimelineEnabled ? (
                  <HeaderLink to={path} key={id} title={label} icon={icon} />
                ) : null
              default:
                return (
                  <HeaderLink to={path} key={id} title={label} icon={icon} />
                )
            }
          })}
        </Group>

        {isMap && (
          <Box visibleFrom="lg">
            <SearchBar className={searchStyles.header} />
          </Box>
        )}

        <Box visibleFrom="sm">
          <HeaderUserMenu isMap={isMap} />
        </Box>
      </Group>
      <Group h="100%" gap="sm" justify="space-between" hiddenFrom="sm">
        <Burger color="white" opened={opened} onClick={toggle} size="sm" />
        <HeaderUserMenu isMap={isMap} />
      </Group>
    </Box>
  )
}
