import { getDataMetadata } from '@/api/data-metadata/api'
import {
  COMMERCIAL_CATEGORIES,
  GOVERNMENT_CATEGORIES,
} from '@/api/data-metadata/constants.ts'
import type {
  DataMetadataCategories,
  ImageProvenanceMetadata,
} from '@/api/data-metadata/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag.ts'
import { useQuery } from '@tanstack/react-query'

export function useDataMetadata() {
  return useQuery({
    queryKey: ['DataMetadata'],
    queryFn: () => getDataMetadata(),
    staleTime: 60 * 60 * 1000, // 1 hour in ms
  })
}

export function useImageProvenanceDataMetadata() {
  return (useDataMetadata().data?.imageProvenance ??
    {}) as ImageProvenanceMetadata
}

export function useCategoriesMetadata() {
  return (useDataMetadata().data?.categories ??
    {}) as Partial<DataMetadataCategories>
}

export function useCategoriesMetadataByEnv() {
  const allCategories = useCategoriesMetadata()
  const isCommercial = useFeatureFlag('commercial')

  const envSpecificCategories = isCommercial
    ? COMMERCIAL_CATEGORIES
    : GOVERNMENT_CATEGORIES

  return Object.fromEntries(
    envSpecificCategories.map((key) => [key, allCategories[key]]),
  )
}

export function useProviderDataMetadata() {
  return useDataMetadata().data?.providers ?? {}
}

export function useSourceDataMetadata() {
  return useDataMetadata().data?.sources ?? {}
}

export function usePlatformDataMetadata() {
  return useDataMetadata().data?.platform ?? {}
}

export function useSensorDataMetadata() {
  return useDataMetadata().data?.sensors ?? {}
}
