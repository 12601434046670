import {
  type FC,
  type MouseEventHandler,
  type MutableRefObject,
  useEffect,
} from 'react'
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  SegmentedControl,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { openSaveSearchModal } from '@/features/AppLayout/Modals'
import { MAP_DRAW_MODES } from '@/features/DantiMap/constants'
import { useProviders } from '@/features/Filters'
import { openSearchFiltersModal } from '@/features/Filters/open-search-filters-modal'
import { useSearch } from '@/features/Search/hooks'
import { useClearAll } from '@/hooks/use-clear-all'
import { useHaveFiltersUpdated } from '@/stores/filters-store'
import {
  useDrawController,
  useDrawMode,
  useIsDrawing,
  useSetDrawMode,
  useStartDrawingShape,
  useStopDrawingShape,
} from '@/stores/map-draw-store'
import {
  type BaseLayerOption,
  useBaseLayer,
  useSetBaseLayer,
} from '@/stores/map-store'
import { useCurrentQuery, useSavedSearchId } from '@/stores/queries-store'
import { MAP_BASE_LAYERS, SAVED_SEARCH_MODES } from '@/utils/constants'
import { LayerPicker } from './LayerPicker'

import styles from '@/features/DantiMap/danti-map.module.css'

type MapControlPanelProps = {
  openFileDialog: MouseEventHandler<HTMLElement>
  map: MutableRefObject<mapboxgl.Map | null>
}

const responsiveMargin = { base: '0', sm: 'sm' }

const DISABLED_BUTTON_STYLE = {
  size: 'xs',
  variant: 'light',
}

const BUTTON_STYLE = {
  size: 'xs',
  variant: 'light',
  color: 'gray',
  c: 'gray.7',
}

export const MapControlPanel: FC<MapControlPanelProps> = ({
  map,
  openFileDialog,
}) => {
  const currentQuery = useCurrentQuery()
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  const isDrawing = useIsDrawing()
  const startDrawingShape = useStartDrawingShape()
  const stopDrawingShape = useStopDrawingShape()
  const setDrawMode = useSetDrawMode()
  const { doLocationSearch } = useSearch()
  const clearAll = useClearAll()
  const haveFiltersUpdated = useHaveFiltersUpdated()
  const { newProviders } = useProviders()
  const drawController = useDrawController()
  const drawMode = useDrawMode()
  const savedSearchId = useSavedSearchId()
  const isSavedSearch = savedSearchId !== ''

  const isClearButtonDisabled = !currentQuery && !haveFiltersUpdated
  const clearButtonStyle = isClearButtonDisabled
    ? DISABLED_BUTTON_STYLE
    : BUTTON_STYLE

  const handleClearClick = () => {
    clearAll()
  }

  useEffect(() => {
    drawController?.changeMode(drawMode)
  }, [drawController, drawMode])

  const doSearchInWindow = () => {
    const b = map.current?.getBounds()
    if (b) {
      const poly = [
        [b.getNorthWest().lng, b.getNorthWest().lat],
        [b.getNorthEast().lng, b.getNorthEast().lat],
        [b.getSouthEast().lng, b.getSouthEast().lat],
        [b.getSouthWest().lng, b.getSouthWest().lat],
        [b.getNorthWest().lng, b.getNorthWest().lat],
      ]
      doLocationSearch(JSON.stringify(poly), 'polygon')
    }
  }

  return (
    <>
      <Group
        className={styles.mapControls}
        style={{ zIndex: 'var(--mantine-z-index-app)' }}
        justify="space-between"
        gap="sm"
      >
        <style>{`body { --danti-head-height: 120px; }`}</style>
        <Group justify="flex-start" gap="xs">
          <Group gap="xs">
            <Text fz="xs" visibleFrom="md">
              Search By:
            </Text>
            <Button.Group>
              <Button
                {...BUTTON_STYLE}
                leftSection={<Icon name="upload_file" size={16} />}
                onClick={openFileDialog}
                visibleFrom="xs"
              >
                File
              </Button>
              <ActionIcon
                {...BUTTON_STYLE}
                size="md"
                hiddenFrom="xs"
                onClick={openFileDialog}
              >
                <Icon name="upload_file" size={16} />
              </ActionIcon>
              <Divider orientation="vertical" />
              <Menu>
                <Menu.Target>
                  <Box>
                    <Button
                      {...BUTTON_STYLE}
                      leftSection={<Icon name="polyline" size={16} />}
                      visibleFrom="xs"
                    >
                      Shape
                    </Button>
                    <ActionIcon {...BUTTON_STYLE} size="md" hiddenFrom="xs">
                      <Icon name="polyline" size={16} />
                    </ActionIcon>
                  </Box>
                </Menu.Target>
                <Menu.Dropdown className={styles.searchByControls}>
                  <Menu.Item
                    leftSection={<Icon name="polyline" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.POLYGON) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.POLYGON)
                        startDrawingShape()
                      }
                    }}
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.POLYGON && {
                        'data-active': true,
                      })}
                  >
                    Polygon
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="screen_search_desktop" />}
                    onClick={() => void doSearchInWindow()}
                  >
                    Current View
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="location_on" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.POINT) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.POINT)
                        startDrawingShape()
                      }
                    }}
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.POINT && {
                        'data-active': true,
                      })}
                  >
                    Point
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="circle" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.CIRCLE) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.CIRCLE)
                        startDrawingShape()
                      }
                    }}
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.CIRCLE && {
                        'data-active': true,
                      })}
                  >
                    Circle
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Button.Group>
          </Group>

          <Group gap="xs" ml={{ ...responsiveMargin }}>
            <Text fz="xs" visibleFrom="md">
              SHOW:
            </Text>
            <LayerPicker buttonStyle={BUTTON_STYLE} />
          </Group>

          <Group gap="xs" ml={{ ...responsiveMargin }}>
            <Text fz="xs" visibleFrom="md">
              Map Type:
            </Text>
            <SegmentedControl
              size="xs"
              tt="capitalize"
              value={baseLayer}
              onChange={(value) => setBaseLayer(value as BaseLayerOption)}
              data={[
                {
                  value: MAP_BASE_LAYERS.dantiStreets,
                  label: (
                    <Flex align="center" fw="bold" gap="xs">
                      <Icon name="map" size={16} />
                      <Box visibleFrom="xs">Default</Box>
                    </Flex>
                  ),
                },
                {
                  value: MAP_BASE_LAYERS.satellite,
                  label: (
                    <Flex align="center" fw="bold" gap="xs">
                      <Icon name="public" size={16} />
                      <Box visibleFrom="xs">Satellite</Box>
                    </Flex>
                  ),
                },
              ]}
            />
          </Group>
        </Group>
        <Group gap="xs">
          <Button
            {...clearButtonStyle}
            visibleFrom="sm"
            leftSection={<Icon name="cancel" size={16} />}
            disabled={isClearButtonDisabled}
            onClick={handleClearClick}
          >
            Clear Search
          </Button>
          <ActionIcon
            {...clearButtonStyle}
            size="md"
            hiddenFrom="sm"
            disabled={isClearButtonDisabled}
            onClick={handleClearClick}
          >
            <Icon name="cancel" size={16} />
          </ActionIcon>

          <Menu trigger="click">
            <Menu.Target>
              <ActionIcon variant="subtle" c="gray">
                <Icon name="more_horiz" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={openSearchFiltersModal}
                disabled={isSavedSearch}
              >
                Filters
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  openSaveSearchModal({
                    isNew: true,
                    providers: newProviders,
                    params: {
                      mode: SAVED_SEARCH_MODES.search,
                      name: currentQuery,
                      query: currentQuery,
                      locations: [],
                    },
                  })
                }
              >
                Save this search
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </>
  )
}
