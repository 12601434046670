import { useNavigate } from 'react-router-dom'
import { ActionIcon, Avatar, Center, Menu, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useShowScores, useToggleShowScores } from '@/stores/config-store'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES, ROUTES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { HeaderMenuItem } from './HeaderMenuItem'
import { SentryFeedbackButton } from './SentryFeedbackButton'

const handleClick = (menuItem: string) => {
  captureEvent(POSTHOG_EVENTS.USER_MENU.CLICKED, { menuItem })
}

export function HeaderUserMenu({ isMap }: { isMap?: boolean }) {
  const navigate = useNavigate()
  const { logOut } = useDantiAuth()
  const shouldShowAccount = useFeatureFlag(
    FEATURE_FLAG_VALUES.accountManagement,
  )
  const shouldShowScores = useFeatureFlag(FEATURE_FLAG_VALUES.showScores)
  const isShowingScores = useShowScores()
  const toggleShowScores = useToggleShowScores()
  const { avatarBackgroundColor, email, userInitials } = useAvatarDetails()

  const itemHeight = '2.5rem'

  return (
    <Menu
      trigger="click-hover"
      transitionProps={{ duration: 100 }}
      offset={18}
      keepMounted
    >
      <Menu.Target>
        <Center style={{ cursor: 'pointer' }} inline>
          <Avatar
            size={32}
            variant="filled"
            color={avatarBackgroundColor}
            radius="50%"
          >
            {userInitials}
          </Avatar>
          <Text
            size="sm"
            visibleFrom={isMap ? 'xl' : 'lg'}
            fw={400}
            c="white"
            mx="xs"
          >
            {email}
          </Text>
          <ActionIcon ml="xs" variant="transparent" size="xs" color="white">
            <Icon name="expand_more" />
          </ActionIcon>
        </Center>
      </Menu.Target>
      <Menu.Dropdown
        bg="rgba(0, 0, 0, 0.733)"
        styles={{
          dropdown: {
            border: 'none',
            backdropFilter: 'blur(1.5px)',
          },
        }}
      >
        {shouldShowAccount && (
          <HeaderMenuItem
            h={itemHeight}
            leftSection={<Icon name="person" filled />}
            onClick={() => {
              handleClick('Account')
              navigate(ROUTES.account)
            }}
          >
            Account
          </HeaderMenuItem>
        )}
        <HeaderMenuItem
          h={itemHeight}
          iconName="question_mark"
          onClick={() => {
            handleClick('Help')
            navigate(ROUTES.help)
          }}
        >
          Help
        </HeaderMenuItem>
        {shouldShowScores && (
          <HeaderMenuItem
            h={itemHeight}
            iconName="speed"
            onClick={toggleShowScores}
          >
            {isShowingScores ? 'Hide' : 'Show'} Result Scores
          </HeaderMenuItem>
        )}
        <SentryFeedbackButton h={itemHeight} />
        <HeaderMenuItem
          h={itemHeight}
          iconName="logout"
          onClick={() => {
            captureEvent(POSTHOG_EVENTS.LOGIN.LOGOUT)
            void logOut()
          }}
        >
          Log Out
        </HeaderMenuItem>
      </Menu.Dropdown>
    </Menu>
  )
}
