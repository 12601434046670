import { getSearchRequest, postSearchRequest } from '@/api/danti-requests'
import type {
  CategoryResponse,
  ExecuteQueryParams,
  SearchResult,
} from '@/api/results/types'
import queryString from 'query-string'

export async function executeQuery(params: ExecuteQueryParams) {
  const response = await postSearchRequest(`v2/query?size=100`, params)
  return response.data as SearchResult
}

export async function getQueryResultsByCategory(
  queryId: string,
  category: string,
  options: {
    size: number
    start: number
  },
) {
  const response = await getSearchRequest(
    `v2/results/${queryId}/${category}?${queryString.stringify(options)}`,
  )
  return response.data as CategoryResponse
}

export async function getQueryResultsByQueryId(queryId: string) {
  const response = await getSearchRequest(`v2/results/${queryId}?size=20`)
  return response.data
}

export async function getPaginatedQueryResults(
  queryId: string,
  category: string,
  start: number,
  pageSize: number,
) {
  const response = await getSearchRequest(
    `v2/results/${queryId}/${category}/${start}/${pageSize}`,
  )
  return response.data
}

export async function getInsightResult(queryId: string) {
  const response = await getSearchRequest(`v2/insight/${queryId}`)
  return response.data
}
