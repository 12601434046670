import { Group, Text } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/api/collections/types.ts'
import { Icon } from '@/components/lib/Icon.tsx'
import { TableHeaderSortCell } from '@/features/Collections/components'
import { SORT_TYPES } from '@/features/Collections/constants'
import { ICONS } from '@/utils/constants.ts'
import { formatDatetime } from '@/utils/format-datetime'
import {
  type CategoryTypes,
  getLabelForResult,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import { isSubCollection } from '@/utils/types/result-type-checkers.ts'
import { createColumnHelper } from '@tanstack/react-table'
import { CollectionDetailResultIcon } from '../CollectionDetailResultIcon'
import { CollectionDetailMenu } from './CollectionDetailMenu'

const columnHelper = createColumnHelper<CollectionItem>()

interface ColumnFunctions {
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
}

export const CollectionDetailTableColumns = [
  columnHelper.accessor((row) => row, {
    id: 'name',
    header: (info) => (
      <TableHeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Name"
        sortType={SORT_TYPES.name}
      />
    ),
    size: 400,
    cell: (info) => {
      const item = info.getValue()

      return (
        <Group gap={12} wrap="nowrap">
          {isSubCollection(item) ? (
            <Icon name={ICONS.folder} filled />
          ) : (
            <CollectionDetailResultIcon
              result={JSON.parse(item.doc) as ResultWithId}
            />
          )}
          <Text>{item.name}</Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor('authoredOn', {
    header: (info) => (
      <TableHeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Authored on"
        sortType={SORT_TYPES.date}
      />
    ),
    cell: (info) => <Text py="sm">{formatDatetime(info.getValue())}</Text>,
  }),

  columnHelper.accessor(
    (row) => {
      const { doc } = row
      return isSubCollection(row)
        ? 'Collection'
        : getLabelForResult(JSON.parse(doc) as ResultWithId)
    },
    {
      id: 'kind',
      size: 70,
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: (_info) => {
        // TODO: Re-enable when backend support is available
        // return (
        //   <TableHeaderSortCell
        //     setSort={(info.table.options.meta as ColumnFunctions).setSort}
        //     label="Kind"
        //     sortType={SORT_TYPES.kind}
        //   />
        // )
        return (
          <Text fz="xs" fw="bold">
            Kind
          </Text>
        )
      },
    },
  ),

  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    size: 70,
    cell: (info) => {
      const item = info.getValue()
      return !isSubCollection(item) && <CollectionDetailMenu item={item} />
    },
  }),
]
