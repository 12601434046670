import { useEffect, useState } from 'react'
import { Chip, Group, Text } from '@mantine/core'
import { useCategoriesMetadataByEnv } from '@/api/data-metadata/use-data-metadata'
import type { SearchCategory } from '@/api/results'
import { Icon } from '@/components/lib/Icon'
import type { FilterArgs } from '@/features/Filters/types'

export function Categories({ value, setter }: FilterArgs<SearchCategory[]>) {
  const categories = useCategoriesMetadataByEnv()
  const allSelected = value.length === 0
  const allCategories = Object.keys(categories) as SearchCategory[]

  // If no value is provided, default to all categories
  const [displayValue, setDisplayValue] = useState<SearchCategory[]>(
    allSelected ? allCategories : value,
  )
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (allSelected) {
      setDisplayValue(allCategories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected])

  useEffect(() => {
    setShowError(false)
  }, [value])

  function handleSelect(newValue: string[]) {
    const isDeselect = newValue.length === 0
    if (isDeselect) {
      setShowError(true)
      return
    }

    setDisplayValue(newValue as SearchCategory[])

    const isSelectAll = newValue.length === Object.keys(categories).length
    setter(isSelectAll ? [] : (newValue as SearchCategory[]))
  }

  return (
    <>
      <Chip.Group multiple value={displayValue} onChange={handleSelect}>
        <Group mt="xs" gap="sm">
          {Object.entries(categories).map(([key, value]) => (
            <Chip
              key={key}
              variant="light"
              size="sm"
              radius="xl"
              icon={<Icon name="check" size="sm" weight={400} />}
              value={key}
            >
              {value}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
      {showError && (
        <Text size="xs" c="red" mt="xs">
          At least one must be selected
        </Text>
      )}
    </>
  )
}
