import React from 'react'
import type { ImageProvidersState } from '@/features/BulkSearch/types.ts'

export function useImageProvidersState(): ImageProvidersState {
  const [providers, setProviders] = React.useState<Set<string>>(new Set())
  const [sources, setSources] = React.useState<Set<string>>(new Set())
  const [platforms, setPlatforms] = React.useState<Set<string>>(new Set())
  const [sensors, setSensors] = React.useState<Set<string>>(new Set())

  function resetImageProvidersState() {
    setProviders(new Set())
    setSources(new Set())
    setPlatforms(new Set())
    setSensors(new Set())
  }

  function imageProvidersSetter(value: { [category: string]: Set<string> }) {
    Object.entries(value).forEach(([category, set]) => {
      switch (category) {
        case 'providers':
          setProviders(set)
          break
        case 'sources':
          setSources(set)
          break
        case 'platforms':
          setPlatforms(set)
          break
        case 'sensors':
          setSensors(set)
          break
      }
    })
  }

  return {
    providers,
    setProviders,
    sources,
    setSources,
    platforms,
    setPlatforms,
    sensors,
    setSensors,
    imageProvidersSetter,
    resetImageProvidersState,
  }
}
