import type { Collection, CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import { ListItem } from '@/features/Collections/components'

export interface CollectionListItemProps {
  data: Collection
  onSelect: (collection: Collection | CollectionItem) => void
}

export function CollectionListItem({
  data,
  onSelect,
}: CollectionListItemProps) {
  const { id, name, lastActivity, status } = data
  const isTerrapin = Boolean(data.refId)
  const navigateToCollection = useNavigateToCollection()
  const onClick = () => navigateToCollection(id)

  return (
    <ListItem
      onClick={onClick}
      data={data}
      onSelect={onSelect}
      icon={
        <Icon name={isTerrapin ? 'folder_special' : 'space_dashboard'} filled />
      }
      date={lastActivity.toString()}
      name={name}
      status={status}
    />
  )
}
