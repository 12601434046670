/* v8 ignore start */
import { DATA_CATEGORIES, type RESULT_CATEGORIES } from '@/utils/constants'
import type { MultiPolygon, Point, Polygon } from 'geojson'
import {
  isEarthquakeResult,
  isEventResult,
  isFemaResult,
  isFileResult,
  isFireResult,
  isPropertyResult,
  isShipResult,
} from './result-type-checkers'
import type { VexcelImage } from './result-types'
export type AssetType = {
  title: string
  type: string
  description?: string
  href: string
  name?: string
}

export interface AnalyticProperty {
  class_name: string
  geometry: Polygon
  score?: number | null
}

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[]
  | AnalyticProperty[]
  | Polygon
  | Point
  | VexcelImage[]
  | undefined

export enum CategoryTypes {
  IMAGE = 'IMAGE',
  COLLECTION = 'COLLECTION',
  DATA = 'DATA',
  PUBLICATION = 'WEB_ARTICLE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  SHIP = 'SHIP',
  FIRE = 'FIRE',
  EARTHQUAKE = 'USGS',
  FEMA = 'FEMA',
  INSIGHT = 'INSIGHT',
  RELATED_IMAGERY = 'RELATED_IMAGERY',
  ANALYTIC = 'ANALYTIC',
  PROPERTY = 'PROPERTY',
  FILE = 'FILE',
}

export interface RawSearchResult {
  title: string
  subtitle: string
  filetype: string
  category: ValueOf<typeof RESULT_CATEGORIES>
  geometry: Polygon | Point | MultiPolygon
  documentId: string
  score: number
  asset: { [key: string]: AssetType }
  // TODO: There is a lot of repetition in these properties, from the root level
  //  to `properties` to some `_d` prefixed properties. We should work on
  //  consolidating these into a coherent object and removing the `_d` prefix.
  //  There is also a lot of inconsistency of data types in similar fields.
  properties: {
    _dId: string
    _dCategory: ValueOf<typeof DATA_CATEGORIES>
    _dPreview?: string
    _dProvider: string
    _dSource?: string
    // _dSourceId: string // TODO: expose in Danti Doc from Anhur
    datetime: string
    link?: string
    resultCategory?: string
    [key: string]: JSONValue
    vexcel_images?: {
      vexcel_images: VexcelImage[]
    }
  }
  authoredOn: string
  source: string
  tags: string[]
  thumbnail: string | null
  license?: string
  description?: string
}

export interface ResultWithId extends RawSearchResult {
  id: string
  properties: RawSearchResult['properties'] & {
    resultCategory?: string
  }
}

export interface DataResult extends ResultWithId {
  id: string
  category: typeof DATA_CATEGORIES.DATA
}

export interface PropertyResult extends Omit<ResultWithId, 'category'> {
  id: string
  category: typeof DATA_CATEGORIES.PROPERTY
}

export interface RawAttomResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'ATTOM'
    datetime: string
    updatedAt: string
    address: {
      oneLine: string
    }
    buildingPermits: {
      buildingPermits: {
        businessName?: string
        description?: string
        effectiveDate?: string
        homeOwnerName?: string
        jobValue?: number
        permitNumber?: string
        status?: string
        type?: string
      }[]
    }
  }
}

export interface RawHazardPropertyResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'HAZARD'
    _dPreview: string
    datetime: string
    property: {
      address: string
      apn: string
      owner: string
      street_address: string
      use_code: string
      zip: string
    }
    assessment: {
      Air_Conditioning: string
      Number_of_Baths: number
      Number_of_Bedrooms: number
    }
    listing_record: {
      list_date: string
      list_price: string
      status: string
    }
    images: {
      images: string[]
    }
    mls_listing_record_details: {
      rm_baths_total: number
      rm_bedrooms_total: number
      in_year_built: number
      rm_baths_partial: number
      if_cooling_features: string
      in_public_remarks: string
    }
  }
}
export interface RawHazardRiskResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'HAZARD'
    datetime: string
    address: string
    danti_highlights: {
      [key: string]: {
        risk?: string
        title: string
        description?: string
        score?: string
      }
    }
  }
}

export interface RawVexcelResult extends PropertyResult {
  properties: PropertyResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.VEXCEL
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'VEXCEL'
    collection: string
    'estimate-date': string
    url: string
  }
}

export interface RawRegridResult extends PropertyResult {
  properties: PropertyResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.REGRID
    address: string
    city?: string
    county?: string
    legalDescription?: string
    geoid?: string
    lotSizeAcres?: number
    lotSizeSquareFeet?: number
    parcelNumber?: string
    updatedAt?: string
  }
}
export interface ImageryResult extends ResultWithId {
  id: string
  thumbnail: string
  properties: ResultWithId['properties'] & {
    cloudPercent?: number
    datetime?: string
    georeferenced?: boolean
    gsd?: number | string
    offNadirAngle?: number | string
    orthorectified?: boolean
    productType?: string
    sensorId?: string
    sumAzimuth?: number
    vendorId?: string
    _dQuality: number
    _dCloudCover: number
    _dCollect?: string
    _dSensor?: string
    _dSource?: string
    _dPlatform?: string
    featureId?: string
  }
  geometry: Polygon | MultiPolygon
  category:
    | typeof DATA_CATEGORIES.IMAGE
    | typeof DATA_CATEGORIES.RELATED_IMAGERY
    | typeof DATA_CATEGORIES.ANALYTIC
}

export function isImageryResult(result: ResultWithId): result is ImageryResult {
  return [
    DATA_CATEGORIES.IMAGE,
    DATA_CATEGORIES.RELATED_IMAGERY,
    DATA_CATEGORIES.ANALYTIC,
  ].includes(result.category)
}

export interface RawAnalyticResult extends ImageryResult {
  id: string
  thumbnail: string
  properties: ImageryResult['properties'] & {
    _dQuality?: number
    _dAnalytic: AnalyticProperty[]
  }
  category: typeof DATA_CATEGORIES.ANALYTIC
}
export interface AnalyticResult extends RawAnalyticResult {
  properties: RawAnalyticResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.ANALYTIC
  }
}

export function isAnalyticResult(
  result: ResultWithId,
): result is AnalyticResult {
  return [DATA_CATEGORIES.ANALYTIC].includes(result.category)
}

export interface RawRelatedImageryResult
  extends Omit<ImageryResult, 'category'> {
  category: typeof DATA_CATEGORIES.RELATED_IMAGERY
}
export interface RelatedImageryResult extends RawRelatedImageryResult {
  properties: RawRelatedImageryResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.RELATED_IMAGERY
  }
}

export function isRelatedImageryResult(
  result: ResultWithId,
): result is RelatedImageryResult {
  return result.category === DATA_CATEGORIES.RELATED_IMAGERY
}

export interface RawInsightResult extends RawSearchResult {
  description: string
  category: typeof DATA_CATEGORIES.INSIGHT
}

export type EmbeddedImages = { url: string; caption: string }[]
export interface RawPublicationResult extends ResultWithId {
  description: string
  category: typeof DATA_CATEGORIES.PUBLICATION
  properties: ResultWithId['properties'] & {
    _dEmbedded?: EmbeddedImages
    _dSource?: string
    summary?: string
  }
}
export interface PublicationResult extends RawPublicationResult {
  properties: RawPublicationResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.PUBLICATION
  }
}

export function isPublicationResult(
  result: ResultWithId,
): result is PublicationResult {
  return result.category === DATA_CATEGORIES.PUBLICATION
}

export interface RawSocialMediaResult extends ResultWithId {
  description: string
  category: typeof DATA_CATEGORIES.SOCIAL_MEDIA
  asset: { thumbnail: AssetType }
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.SOCIAL_MEDIA
    id: string
    title: string
    source: string
    summary: string
    src_image_url?: string
    start_datetime?: string | null
    end_datetime?: string | null
    created?: string | null
    updated?: string | null
    platform?: string | null
    instruments?: string | null
    constellation?: string | null
    mission?: string | null
    providers?: string[] | null
    gsd?: string | null
    topic_scores_v2?:
      | { name: string; score: number }[]
      | {
          topic_scores_v2:
            | { name: string; score: number }[]
            | { topic_scores_v2: { name: string; score: number }[] | null }
            | null
        }
      | null
    labeled_categories?:
      | { name: string; score: number }[]
      | {
          labeled_categories:
            | { name: string; score: number }[]
            | { labeled_categories: { name: string; score: number }[] | null }
            | null
        }
      | null
    _timestamp?: string | null
    _completed?: string | null
    cluster_id?: string | null
    cluster_size?: number | null
    sentiment?: number | null
    emotions?:
      | string[]
      | { emotions: string[] | { emotions: string[] | null } | null }
      | null
    veracity?: string | null
    lang?: string | null
    filtered_cluster_size?: number | null
  }
}

export interface SocialMediaResult extends RawSocialMediaResult {
  properties: RawSocialMediaResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.SOCIAL_MEDIA
  }
}

export function isSocialMediaResult(
  result: ResultWithId,
): result is SocialMediaResult {
  return result.category === DATA_CATEGORIES.SOCIAL_MEDIA
}

export interface RawFireResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'FIRMS'
    firms_metadata: {
      latitude: number
      longitude: number
      scan: number
      track: number
      acq_date: string
      acq_time: number
      bright_ti4: number
      bright_ti5: number
      frp: number
      instrument: string
      satellite: string
      confidence: string
      version: string
      daynight: string
    }
  }
}
export interface RawVesselResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'SPIRE'
    recordType: string
    datetime: string
    updateTimestamp: string
    staticData: {
      name: string | null
      imo: number | null
      mmsi: number
      shipType?: string
      flag?: string
      callsign?: string
    }
    lastPositionUpdate: {
      latitude: number
      longitude: number
      navigationalStatus: string
      speed: number
      timestamp: string
      updateTimestamp: string
    }
    currentVoyage: {
      destination: string
      eta: string
      timestamp: string
      updateTimestamp: string
    } | null
  }
}
export interface PropertyResult extends Omit<ResultWithId, 'category'> {
  id: string
  category: typeof DATA_CATEGORIES.PROPERTY
}
export interface RawPortResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'SPIRE'
    state: string
    recordType: string
    timestamp: string
    datetime: string
    updateTimestamp: string
    ata: string
    atd: string
    draughtAta: string
    draughtAtd: string
    draughtChange: number
    duration: number
    location: {
      name: string
      centerPoint: {
        latitude: number
        longitude: number
      }
      country: string
      id: string
      type: string
      unlocode: string
    }
    vessel: {
      id: string
      staticData: {
        callsign: string
        imo: number
        mmsi: number
        name: string
        shipType: string
      }
    }
  }
}

export function getLabelForResult(result: ResultWithId) {
  if (isAnalyticResult(result)) {
    return 'Analytic'
  }
  if (isImageryResult(result)) {
    return 'Image'
  }
  if (isSocialMediaResult(result)) {
    return 'Social'
  }
  if (isFireResult(result)) {
    return 'Fire'
  }
  if (isShipResult(result)) {
    return 'Ship'
  }
  if (isFileResult(result)) {
    return 'File'
  }
  if (isPropertyResult(result)) {
    return 'Property'
  }
  if (isFemaResult(result)) {
    return 'FEMA'
  }
  if (isEarthquakeResult(result)) {
    return 'Earthquake'
  }
  if (isEventResult(result)) {
    return 'Event'
  }

  return 'News'
}

export interface CoreLogicBuildingSummary {
  bathroomsCount: number | null
  bedroomsCount: number | null
  buildingsCount: number | null
  livingAreaSquareFeet: number | null
  fullBathroomsCount: number | null
  halfBathroomsCount: number | null
}

export interface CoreLogicFireStation {
  address: string
  cityName: string
  drivingDirection: {
    distanceInMiles: number
    durationInSeconds: number
  }
  fireResponseAreaName: string
  fireResponseAreaType: string
  fraId: string
  inFra: boolean
  latitude: number
  longitude: number
  stateCode: string
  stationId: number
  stationName: string
  zipCode: string
}

export interface CoreLogicDistanceMetrics {
  coastalDistance: {
    coastalDetail: {
      distanceFeet: number
      name: string
    }
    isOnMainland: boolean
    location: {
      latitude: number
      longitude: number
    }
    majorSeawardWaterDetail: {
      distanceFeet: number
      name: string
    }
    minorSeawardWaterDetail: {
      distanceFeet: number
      name: string
    }
    oceanOrGulfDetail: {
      distanceFeet: number
      name: string
    }
  }
  distanceToFirestation: {
    count: number
    distance: number
    fireStations: {
      fireStations: CoreLogicFireStation[]
    }
    ignoreFireDistrict: boolean
    location: {
      latitude: number
      longitude: number
    }
    maxFireStations: number
  }
  distanceToShore: {
    attributes: {
      county: string
      distancefeet: number
      fipsstco: string
      state: string
    }
    inputDistance: number
    location: {
      latitude: number
      longitude: number
    }
  }
}

// CoreLogic Risk Interfaces
export interface CoreLogicCrimeRisk {
  riskIndex: number
  propertyCrimeIndex: number
  violentCrimeIndex: number
  propertyCrimeDetail: {
    burglaryIndex: number
    larcenyIndex: number
  }
  violentCrimeDetail: {
    assaultIndex: number
    murderIndex: number
    rapeIndex: number
    robberyIndex: number
  }
}

export interface CoreLogicEarthquakeRisk {
  MMIDescriptionOfDamage: string
  MMIDescriptionOfShaking: string
  MMIValue: number
  PeakGroundAccelerationRiskDescription: string
  RiskDescription: string
  RiskScore: number
}

export interface CoreLogicFloodRisk {
  riskScore: number
  riskRating: string
  floodZone: string
  baseRiskScore: number
  baseRiskRating: string
  propertyElevationFeet: number
  floodRiskScoreDetail: {
    communityName: string
    communityNumber: string
    mapDate: string
  }
}

export interface CoreLogicHailRisk {
  riskScore: number
  riskRating: string
  claimFrequency: number
  claimRating: string
  avgAnnualLoss: number
}

export interface CoreLogicCoastalStormRisk {
  riskDescription: string
}

// CoreLogic Property Interfaces
export interface CoreLogicLocation {
  address: string
  city: string
  state: string
  zip: string
}

export interface CoreLogicAddressMatch {
  addressMatchCode: string
  addressMatchCodeDescription: string
  addressType: string
  matchCode: string
  propertyMatchScore: number
  resultCode: string
}

export interface CoreLogicBuildingCharacteristics {
  assessedValueLandAndImprovements: string
  assessmentYear: string
  assessorParcelID: string
  assumedFirst: string
  atticTypeDescription: string
  basementDescription: string
  basementType: string
  baths: string
  bedrooms: string
  buildingClass: string
  censusBlockIdentifier: string
  censusTractIdentifier: string
  centralizedCooling: string
  city: string
  county: string
  countyLandUseDesc: string
  exempt: string
  exemptSpecified: string
  exteriorWalls: string
  hasPool: string
  improvementsCondition: string
  improvementsTaxableLandValue: string
  improvementsTaxablePropertyValue: string
  landUseDesc: string
  lastRecordingDate: string
  lastSaleDate: string
  lastSalePrice: string
  livingArea: string
  location: CoreLogicLocation
  lotAreaAcres: string
  marketValue: string
  numFullBaths: string
  numStories: string
  numberOfBuildings: string
  otherCoolingDescription: string
  owner: string
  ownerOccupiedIndicator: string
  pendingRecordIndicator: string
  plus4: string
  pricePerSquareFoot: string
  priorFirstMortgageAmount: string
  priorFirstMortgageInterestRatePercent: string
  priorFirstMortgageInterestRateTypeDescription: string
  priorFirstMortgageTypeDescription: string
  priorRecordingDate: string
  priorSaleDate: string
  priorSalePrice: string
  priorSaleTypeDescription: string
  roofMaterial: string
  sellerName: string
  state: string
  street: string
  styleTypeDescription: string
  typeOfParking: string
  yrBuilt: string
  zip: string
}

export interface CoreLogicStructureFeatures {
  airConditioning: { typeCode: string | null }
  dormerWindows: { count: number | null }
  firePlaces: { count: number | null; typeCode: string | null }
  heating: { typeCode: string | null }
  passengerElevators: { count: number | null }
  plumbing: { typeCode: string | null }
}

export interface CoreLogicStructureInterior {
  attic: { typeCode: string }
  basement: {
    finishPercent: number | null
    finishTypeCode: string
    typeCode: string
  }
  bathroomFixtures: { count: number | null }
  ceiling: { heightFeet: number | null; typeCode: string | null }
  flooring: { coverTypeCode: string | null; typeCode: string | null }
  walls: { typeCode: string | null }
}

export interface CoreLogicStructureExterior {
  parking: {
    carportAreaSquareFeet: number | null
    garageTypeCode: string
    parkingSpacesCount: number
    primaryAreaSquareFeet: number | null
    secondAreaSquareFeet: number | null
    typeCode: string
  }
  patios: {
    areaSquareFeet: number | null
    count: number | null
    typeCode: string | null
  }
  pool: null // Remove union until we know the actual type
  porches: {
    areaSquareFeet: number | null
    count: number | null
    secondPorchAreaSquareFeet: number | null
    typeCode: string | null
  }
  roof: {
    coverTypeCode: string
    typeCode: string
  }
  walls: {
    typeCode: string
  }
}

export interface CoreLogicBuilding {
  constructionDetails: {
    buildingImprovementConditionCode: string | null
    buildingImprovementTypeCode: string | null
    buildingImprovementValue: number | null
    buildingQualityTypeCode: string | null
    buildingRemodelTypeCode: string | null
    buildingStyleTypeCode: string | null
    constructionTypeCode: string | null
    effectiveYearBuilt: number | null
    foundationTypeCode: string | null
    frameTypeCode: string | null
    yearBuilt: number
  }
  interiorArea: {
    aboveGradeAreaSquareFeet: number | null
    aboveGroundFloorAreaSquareFeet: number | null
    basementAreaSquareFeet: number | null
    buildingAdditionsAreaSquareFeet: number | null
    buildingAdjustedAreaSquareFeet: number | null
    buildingAreaSquareFeet: number
    buildingGrossAreaSquareFeet: number | null
    entryLevelFloorAreaSquareFeet: number | null
    finishedBasementAreaSquareFeet: number | null
    groundFloorAreaSquareFeet: number | null
    livingAreaSquareFeet: number
    secondFloorAreaSquareFeet: number | null
    thirdFloorAreaSquareFeet: number | null
    unfinishedBasementAreaSquareFeet: number | null
    universalBuildingAreaSquareFeet: number
    universalBuildingAreaSquareFeetSourceCode: string
  }
  interiorRooms: {
    basementRoomsCount: number | null
    bathroomsCount: number | null
    bedroomsCount: number
    familyRoomsCount: number | null
    fullBathroomsCount: number
    halfBathroomsCount: number | null
    kitchensCount: number | null
    livingRoomsCount: number | null
    oneQtrBathroomsCount: number | null
    threeQtrBathroomsCount: number | null
    totalCount: number | null
  }
  structureClassification: {
    buildingClassCode: string
    buildingTypeCode: string
    fireInsuranceTypeCode: string | null
    fireSprinklerTypeCode: string | null
    gradeTypeCode: string | null
  }
  structureExterior: CoreLogicStructureExterior
  structureFeatures: CoreLogicStructureFeatures
  structureFootprint: {
    depthFeet: number | null
    widthFeet: number | null
  }
  structureId: {
    buildingComments: string | null
    buildingName: string | null
    buildingNumber: string
    buildingSectionNumber: string | null
    compositeBuildingLinkageKey: string
    sequenceNumber: number
  }
  structureInterior: CoreLogicStructureInterior
  structureUnitsSummary: {
    commercialCount: number | null
    residentialCount: number | null
    vacantCount: number | null
  }
  structureVerticalProfile: {
    floorNumber: number | null
    storiesCount: number
    storiesTypeCode: string
  }
}

export interface CoreLogicVoluntaryLiens {
  clip: string
  countyMortgageCoverageSummary: {
    firstMortgageDate: number
    firstReleaseDate: number
    lastMortgageDate: number
    lastReleaseDate: number
    standardizedCounty: string
    standardizedState: string
  }
  enriched: null // Remove union until we know the actual type
  openLienEquityAndLTV: {
    estimatedCombinedLTV: number
    estimatedEquity: number
    purchaseAmount: number
    purchaseCombinedLTV: number
    purchaseRecordingDate: number
    totalAmountOfOpenMortgageLiens: number
    totalNumberOfOpenMortgageLiens: number
  }
  openLiens: {
    openLiens: any[] // Update if openLiens has a specific structure
  }
}

export interface CoreLogicPropertyDetail {
  allBuildingsSummary: CoreLogicBuildingSummary
  buildings: {
    buildings: CoreLogicBuilding[]
  }
}

export interface CoreLogicPropertyIdentifiers {
  foundationType: {
    basement: string
    foundationType: string
  }
  propertyAPN: {
    apnParcelNumberFormatted: string
    fipsCode: string
    universalParcelId: string
  }
  propertyAddress: {
    city: string | null
    county: string | null
    state: string | null
    streetAddress: string | null
    zipCode: string | null
    zipPlus4: string | null
  }
}

// Raw CoreLogic Result Types
export interface RawCoreLogicRiskResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.PROPERTY
    _dProvider: 'CORELOGIC'
    _dAddress: string
    datetime: string
    crimeRisk?: CoreLogicCrimeRisk
    earthquakeRisk?: CoreLogicEarthquakeRisk
    floodRisk?: CoreLogicFloodRisk
    hailRisk?: CoreLogicHailRisk
    coastalStormRisk?: CoreLogicCoastalStormRisk
  }
}

export interface RawCoreLogicPropertyResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.PROPERTY
    _dProvider: 'CORELOGIC'
    _dAddress: string
    _dId: string
    _dPlaceId: string
    _dPublic: boolean
    _dRecordType: string
    _dSource: string
    _dTimeOfIngest: string
    datetime: string
    addressMatchInformation: CoreLogicAddressMatch
    buildingCharacteristics: CoreLogicBuildingCharacteristics
    buildingFeatures: {
      allBuildingsSummary: CoreLogicBuildingSummary
      buildings: {
        buildings: CoreLogicBuilding[]
      }
      clip: string
    }
    clip: string
    coastalDistance: CoreLogicDistanceMetrics['coastalDistance']
    distanceToFirestation: CoreLogicDistanceMetrics['distanceToFirestation']
    distanceToShore: CoreLogicDistanceMetrics['distanceToShore']
    foundationType: CoreLogicPropertyIdentifiers['foundationType']
    involuntaryLiens: Record<string, never>
    propertyAPN: CoreLogicPropertyIdentifiers['propertyAPN']
    propertyAddress: CoreLogicPropertyIdentifiers['propertyAddress']
    propertyDetail: CoreLogicPropertyDetail
    roofAge?: {
      ageOfRoof: number
      clip: string
      confidence: string
      roofYear: number
      yearBuilt: number
    }
    v1PropertyId: string
    voluntaryLiens: CoreLogicVoluntaryLiens
  }
}
