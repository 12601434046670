import { em, Group } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useSearch, useSearchInterface } from '@/features/Search/hooks'
import { useCurrentQuery, useSavedSearchName } from '@/stores/queries-store'
import { SplitSearch } from './SplitSearch'
import { UnifiedSearch } from './UnifiedSearch'

interface SearchInputProps {
  className?: string
}

export function SearchBar({ className }: SearchInputProps) {
  const currentQuery = useCurrentQuery()
  const { doTextSearch, doSplitSearch } = useSearch()
  const { handleInlineHotkeyPress, isSplit, searchRef } = useSearchInterface()
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  const savedSearchName = useSavedSearchName()
  const isSavedSearch = savedSearchName !== ''
  const value = isSavedSearch ? '' : currentQuery
  return (
    <Group key={value} className={className}>
      {isSplit || isMobile ? (
        <SplitSearch
          ref={searchRef}
          {...{ doSplitSearch, handleInlineHotkeyPress }}
        />
      ) : (
        <UnifiedSearch
          ref={searchRef}
          {...{ doTextSearch, handleInlineHotkeyPress, value }}
        />
      )}
    </Group>
  )
}

export default SearchBar
