import { type FocusEvent, useState } from 'react'
import {
  ActionIcon,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Select,
  Stack,
  Table,
  TextInput,
} from '@mantine/core'
import {
  type BulkSearchGeolocationWithId,
  validateLocation,
} from '@/api/search'
import { Icon } from '@/components/lib/Icon'
import { TerrapinLocationType } from '@/features/AppLayout'
import type {
  LocationApiFilterValue,
  LocationTypeApiFilterValue,
} from '@/utils/types/filter-types'
import type { AxiosError } from 'axios'
import { getLocationType, getLocationValue } from './TerrapinLocation'
import { TerrapinStaticMapDisplay } from './TerrapinStaticMapDisplay'

import styles from './terrapin-location-panel.module.css'

interface ValidationAPIError {
  message: string
}

interface TerrapinLocationRowProps {
  location: BulkSearchGeolocationWithId
  onChange: (location: BulkSearchGeolocationWithId) => void
  onRemove: (id: string) => void
  onMapClick: () => void
}

const ValidationStatuses = {
  VALID: 'valid',
  VALIDATED: 'validating',
  INVALID: 'invalid',
} as const

type ValidationStatus = ValueOf<typeof ValidationStatuses>

const MAP_IMAGE_WIDTH = 70
const DEFAULT_ERR_MSG = 'Invalid location'

const isEmpty = (value: string) => value.trim().length === 0

export function TerrapinLocationRow({
  location,
  onChange,
  onRemove,
  onMapClick,
}: TerrapinLocationRowProps) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [locationError, setLocationError] = useState<string | undefined>(
    location.error,
  )
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>(
    location.error || !location.metadata
      ? ValidationStatuses.INVALID
      : ValidationStatuses.VALID,
  )
  const locationValue = getLocationValue(location)
  const locationType = getLocationType(location)
  const locationTypes = [
    { label: 'Point', value: TerrapinLocationType.latLong },
    { label: 'MGRS', value: TerrapinLocationType.mgrs },
  ]

  const displayStaticMap =
    !isEmpty(locationValue) &&
    !locationError &&
    validationStatus !== ValidationStatuses.INVALID

  const displayStatusIcon = () => {
    if (validationStatus === ValidationStatuses.INVALID) return

    if (validationStatus === ValidationStatuses.VALIDATED) {
      return <Loader size="xs" />
    }

    if (validationStatus === ValidationStatuses.VALID) {
      return (
        <Icon
          name="check_circle"
          style={{
            color: 'var(--mantine-color-green-4)',
          }}
        />
      )
    }

    return
  }

  const validateCoordinates = (value: string, type: TerrapinLocationType) => {
    setValidationStatus(ValidationStatuses.VALIDATED)

    const newFilters: [LocationApiFilterValue, LocationTypeApiFilterValue] = [
      { type: 'LOCATION', value: value },
      { type: 'LOCATIONTYPE', value: type },
    ]

    validateLocation(newFilters)
      .then((response) => {
        const { data, status } = response

        // 206 means location was not found. Throw error as we cannot use the value
        if (status === 206) throw new Error(DEFAULT_ERR_MSG)

        onChange({
          ...location,
          error: undefined,
          filters: data.filters,
          metadata: data.metadata,
        })

        setValidationStatus(ValidationStatuses.VALID)
      })
      .catch(({ response }: AxiosError) => {
        const errorData = response?.data as ValidationAPIError
        const errorMessage = errorData?.message || DEFAULT_ERR_MSG
        setLocationError(errorMessage)
        setValidationStatus(ValidationStatuses.INVALID)

        onChange({
          ...location,
          filters: newFilters,
          error: errorMessage,
          metadata: null, // remove metadata as location is not valid
        })
      })
  }

  const handleCoordinatesBlur = (event: FocusEvent<HTMLInputElement>) => {
    const updatedValue = event.currentTarget.value

    if (isEmpty(updatedValue)) {
      setValidationStatus(ValidationStatuses.INVALID)
      onChange({
        ...location,
        name: location.name,
        filters: [
          { type: 'LOCATION', value: '' },
          { type: 'LOCATIONTYPE', value: locationType },
        ],
        metadata: null, // remove metadata as location has no coords
      })
      return
    }

    if (updatedValue === locationValue) return

    validateCoordinates(updatedValue, locationType)
  }

  const handleLocationTypeChange = (updatedLocationType: string | null) => {
    if (!updatedLocationType) return

    onChange({
      ...location,
      filters: [
        { type: 'LOCATION', value: locationValue },
        { type: 'LOCATIONTYPE', value: updatedLocationType },
      ],
    })

    if (!isEmpty(locationValue)) {
      setLocationError(undefined)
      validateCoordinates(
        locationValue,
        updatedLocationType as TerrapinLocationType,
      )
    }
  }

  const cancelDeleteButtonProps = {
    size: 'sm',
    variant: 'transparent',
    fw: 400,
    px: 0,
    color: 'black',
  }

  return (
    <Table.Tr className={styles.locationRow}>
      <Table.Td>
        <Select
          data={locationTypes}
          value={locationType}
          onChange={handleLocationTypeChange}
          allowDeselect={false}
        />
      </Table.Td>
      <Table.Td maw={200}>
        <TextInput
          defaultValue={locationValue}
          placeholder="Add a lat/long or MGRS"
          error={locationError}
          onBlur={handleCoordinatesBlur}
          onChange={() => setLocationError(undefined)}
          rightSection={displayStatusIcon()}
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          defaultValue={location.name}
          placeholder="Add a name"
          onBlur={(event) =>
            onChange({
              ...location,
              name: event.currentTarget.value,
            })
          }
        />
      </Table.Td>
      {showDeleteConfirmation ? (
        <Table.Td colSpan={2}>
          <Group gap="sm" justify="center" wrap="nowrap">
            <Button
              {...cancelDeleteButtonProps}
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              {...cancelDeleteButtonProps}
              color="red"
              onClick={() => onRemove(location._id)}
            >
              Delete
            </Button>
          </Group>
        </Table.Td>
      ) : (
        <>
          <Table.Td w={MAP_IMAGE_WIDTH} pos="relative">
            <LoadingOverlay
              visible={validationStatus === ValidationStatuses.VALIDATED}
              loaderProps={{ size: 'xs' }}
            />
            {displayStaticMap ? (
              <TerrapinStaticMapDisplay
                location={location}
                onClick={onMapClick}
              />
            ) : (
              <Stack h={34} bg="gray.4">
                <Icon style={{ margin: 'auto' }} name="warning_amber" />
              </Stack>
            )}
          </Table.Td>
          <Table.Td align="center" valign="middle">
            <ActionIcon
              variant="white"
              color="black"
              onClick={() =>
                locationValue
                  ? setShowDeleteConfirmation(true)
                  : onRemove(location._id)
              }
            >
              <Icon name="delete_outline" />
            </ActionIcon>
          </Table.Td>
        </>
      )}
    </Table.Tr>
  )
}
