import type { Collection, CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon.tsx'
import { ListItem } from '@/features/Collections/components'
import { ICONS } from '@/utils/constants.ts'
import { isSubCollection } from '@/utils/types/result-type-checkers.ts'
import type { ResultWithId } from '@/utils/types/result-types'
import { CollectionDetailResultIcon } from './CollectionDetailResultIcon'

export interface CollectionDetailListItemProps {
  data: CollectionItem
  onSelect: (item: CollectionItem | Collection) => void
}

export function CollectionDetailListItem({
  data,
  onSelect,
}: CollectionDetailListItemProps) {
  const { name, authoredOn } = data
  const searchResultItem = isSubCollection(data)
    ? null
    : (JSON.parse(data.doc) as ResultWithId)
  return (
    <ListItem
      data={data}
      onSelect={onSelect}
      icon={
        searchResultItem ? (
          <CollectionDetailResultIcon result={searchResultItem} />
        ) : (
          <Icon name={ICONS.folder} />
        )
      }
      date={authoredOn}
      name={name}
    />
  )
}
