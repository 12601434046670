import {
  ActionIcon,
  FileInput,
  Loader,
  Space,
  Text,
  Textarea,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { StatusBlock } from '@/components/lib/StatusBlock'
import {
  type LocationsState,
  LocationStatuses,
} from '@/features/BulkSearch/types'
import { TerrapinLocationPanel } from './TerrapinLocationPanel'

export function AddLocations({
  locationsInputValue,
  setLocationsInputValue,
  locationsFile,
  setLocationsFile,
  locationsList,
  setLocationsList,
  locationsError,
  setLocationsError,
  status,
  resetLocationsState,
}: LocationsState) {
  if (status === LocationStatuses.LOADING) {
    return (
      <StatusBlock
        title="Validating your input"
        icon={<Loader />}
        description={
          <>
            Danti is processing the locations you entered.
            <br />
            Please wait just a moment.
          </>
        }
      />
    )
  }

  if (status === LocationStatuses.FRESH) {
    return (
      <>
        <Text>
          Enter your search locations below - one location per line - to search
          every location at once.
          <br />
          You can also upload a file with the button below.
          <br />
          After Danti verifies your input, you can add more preferences and
          filters. Once you&apos;re done, we&apos;ll create a Saved Search
          folder for you containing the search results.
        </Text>
        <Space h="md" />
        <Textarea
          placeholder="Latitude, Longitude (6.3979, 3.5673) or MGRS (4QFJ12345678) format"
          resize="vertical"
          autosize
          maxRows={8}
          minRows={8}
          disabled={Boolean(locationsFile)}
          error={locationsInputValue && locationsError}
          value={locationsInputValue}
          onChange={({ currentTarget }) => {
            setLocationsError(null)
            setLocationsInputValue(currentTarget.value)
          }}
        />
        <Space h="md" />
        <FileInput
          w="200px"
          label="Upload a file"
          placeholder="Pick File"
          accept="text/csv, text/tab-separated-values"
          disabled={Boolean(locationsInputValue)}
          error={locationsFile && locationsError}
          value={locationsFile}
          onChange={(file) => {
            setLocationsError(null)
            setLocationsFile(file)
          }}
          rightSection={
            locationsFile && (
              <ActionIcon
                aria-label="Remove file"
                variant="subtle"
                radius="xl"
                size="md"
                color={locationsFile && locationsError ? 'red' : 'gray'}
                onClick={resetLocationsState}
              >
                <Icon name="cancel" size="sm" />
              </ActionIcon>
            )
          }
        />
      </>
    )
  }

  // Step 1b: Locations List & Map
  return (
    <TerrapinLocationPanel
      setLocationsList={setLocationsList}
      locations={locationsList}
    />
  )
}
