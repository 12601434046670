/* v8 ignore start */
export const DATA_METADATA_CATEGORIES = {
  // Government environmnet only:
  IMAGE: 'IMAGE',
  WEB_ARTICLE: 'WEB_ARTICLE',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  SHIP: 'SHIP',
  FIRE: 'FIRE',

  // Commercial environment only:
  DATA: 'DATA',
  ATTOM: 'ATTOM',
  HAZARD: 'HAZARD',
  REGRID: 'REGRID',

  // Currently unsupported but useful for both environments:
  EVENT: 'EVENT',
  FEMA: 'FEMA',
  USGS: 'USGS',
} as const

export const GOVERNMENT_CATEGORIES = [
  DATA_METADATA_CATEGORIES.IMAGE,
  DATA_METADATA_CATEGORIES.WEB_ARTICLE,
  DATA_METADATA_CATEGORIES.SOCIAL_MEDIA,
  DATA_METADATA_CATEGORIES.SHIP,
  DATA_METADATA_CATEGORIES.FIRE,
] as const

export const COMMERCIAL_CATEGORIES = [
  DATA_METADATA_CATEGORIES.DATA,
  DATA_METADATA_CATEGORIES.ATTOM,
  DATA_METADATA_CATEGORIES.HAZARD,
  DATA_METADATA_CATEGORIES.REGRID,
] as const
