import { useMemo } from 'react'
import type { Results } from '@/api/results/types'
import { TIMELINE_CATEGORIES } from '@/features/ResultsTimeline/constants'
import type {
  HistogramDataPoint,
  TimelineDoctypes,
} from '@/features/ResultsTimeline/types'
import {
  datetime,
  findMaxDate,
  findMinDate,
  formatDate,
} from '@/utils/format-datetime'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

interface HistogramData {
  data: HistogramDataPoint[]
  dateRange: {
    min: Date
    max: Date
  }
  categories: TimelineDoctypes[]
}

const MAX_BINS = 20

export function useHistogramData(results: Partial<Results>): HistogramData {
  const memoResults = useDeepCompareMemoize(results)

  return useMemo(() => {
    // Find global min and max dates using local timezone
    const activeCategories = new Set<TimelineDoctypes>()

    const effectiveResults = Object.fromEntries(
      Object.entries(memoResults).filter(([category]) =>
        TIMELINE_CATEGORIES.includes(category),
      ),
    )
    Object.entries(effectiveResults).forEach(([category, categoryResults]) => {
      if (categoryResults.length > 0) {
        activeCategories.add(category as TimelineDoctypes)
      }
    })
    const flatResults = Object.values(effectiveResults).flat()
    // Find global min and max dates using local timezone
    let minDate = findMinDate(flatResults.map((result) => result.authoredOn))
    let maxDate = findMaxDate(flatResults.map((result) => result.authoredOn))

    // If no valid dates found, return empty structure
    if (!minDate || !maxDate) {
      const now = new Date()
      return {
        data: [],
        dateRange: { min: now, max: now },
        categories: [] as TimelineDoctypes[],
      }
    }

    // Start both dates at the beginning of their respective days
    minDate = minDate.startOf('day')
    maxDate = maxDate.endOf('day')
    const timeRange = maxDate.toDate().getTime() - minDate.toDate().getTime()
    const totalDays = Math.ceil(timeRange / (1000 * 60 * 60 * 24))
    const numberOfBins = Math.min(totalDays, MAX_BINS)
    const daysPerBin = Math.ceil(totalDays / numberOfBins)

    // Initialize bins with all categories set to 0
    const data: HistogramDataPoint[] = []

    // Create exactly numberOfBins bins
    for (let index = 0; index < numberOfBins; index++) {
      const binStartDate = minDate.add(index * daysPerBin, 'day').startOf('day')
      const dateString = formatDate(binStartDate.toString())
      const dataPoint: HistogramDataPoint = { date: dateString }
      activeCategories.forEach((category) => {
        dataPoint[category] = 0
      })
      data.push(dataPoint)
    }

    // Populate bins with data
    Object.entries(results).forEach(([category, categoryResults]) => {
      categoryResults.forEach((result) => {
        const date = datetime(result.authoredOn)
        if (date.isValid()) {
          // Find the appropriate bin for this date
          const daysSinceStart = date.diff(minDate, 'day')
          const binIndex = Math.min(
            Math.floor(daysSinceStart / daysPerBin),
            numberOfBins - 1,
          )

          const value = data[binIndex][category as TimelineDoctypes]
          if (binIndex >= 0) {
            data[binIndex][category as TimelineDoctypes] = (value || 0) + 1
          }
        }
      })
    })

    return {
      data,
      dateRange: {
        min: minDate.toDate(),
        max: maxDate.toDate(),
      },
      categories: [...activeCategories] as TimelineDoctypes[],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoResults])
}
