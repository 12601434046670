import { RESULT_CATEGORIES } from '@/utils/constants'
export const COLOR_SIZE = 8
export const TABS_MAP = {
  images: RESULT_CATEGORIES.IMAGE,
  news: RESULT_CATEGORIES.PUBLICATION,
  social: RESULT_CATEGORIES.SOCIAL_MEDIA,
  data: RESULT_CATEGORIES.DATA,
  fire: RESULT_CATEGORIES.FIRE,
  ship: RESULT_CATEGORIES.SHIP,
  property: RESULT_CATEGORIES.PROPERTY,
}
