import { emitter, EVENTS } from '@/events'
import type {
  BaseLayerOption,
  MapLayerOptions,
  MapState,
  MapStateValues,
} from '@/stores/map-store/types'
import { MAP_BASE_LAYERS } from '@/utils/constants'
import { createStore } from '@/utils/create-store.ts'
import { MAP_LAYER_OPTIONS } from './constants'

export const initialState = {
  activeLayers: Object.values(MAP_LAYER_OPTIONS),
  activeOrthoLayerGroupIds: [],
  baseLayer: MAP_BASE_LAYERS.dantiStreets,
  currentMapBounds: null,
  gridCellProperties: {},
  hoveredExtentIds: [],
  hoveredLayerId: null,
  mapBbox: null,
  pointViewPort: null,
  selectedExtentIds: [],
  selectedPointId: null,
  zoomOrthoLayerGroupId: null,
} as MapStateValues

export const useMapStore = createStore<MapState>({
  initialState,
  actions: (setState, getState) => ({
    clearActiveLayers: () => setState({ activeLayers: [] }),
    clearOrthoLayerGroupIds: () => {
      emitter.emit(EVENTS.mapStore.activeOrthoLayersUpdated, {
        activeOrthoLayerGroupIds: [],
      })
      setState({ activeOrthoLayerGroupIds: [] })
    },
    setActiveLayers: (layers: MapLayerOptions[]) => {
      setState({
        activeLayers: [...layers],
      })
      emitter.emit(EVENTS.mapStore.activeLayersUpdated, {
        activeLayers: [...layers],
      })
    },
    setActiveOrthoLayerGroupIds: (ids: string[]) => {
      setState({ activeOrthoLayerGroupIds: [...ids] })
      emitter.emit(EVENTS.mapStore.activeOrthoLayersUpdated, {
        activeOrthoLayerGroupIds: ids,
      })
    },
    setBaseLayer: (baseLayer: BaseLayerOption) => setState({ baseLayer }),
    setCurrentMapBounds: (currentMapBounds: mapboxgl.LngLatBounds | null) =>
      setState({ currentMapBounds }),
    setHoveredExtentIds: (extentIds: string[]) => {
      setState({ hoveredExtentIds: extentIds })
      emitter.emit(EVENTS.mapStore.hoveredExtentsUpdated, {
        hoveredExtentIds: extentIds,
      })
    },
    setHoveredLayerId: (hoveredLayerIdPrefix) => {
      const { hoveredLayerId } = getState()
      if (hoveredLayerId !== hoveredLayerIdPrefix) {
        setState({ hoveredLayerId: hoveredLayerIdPrefix })
        emitter.emit(EVENTS.mapStore.hoveredLayerIdUpdated, {
          hoveredLayerId: hoveredLayerIdPrefix,
        })
      }
    },
    setMapBbox: (bbox) => setState({ mapBbox: bbox }),
    setPointViewPort: (viewPort) => {
      setState({ pointViewPort: viewPort })
      emitter.emit(EVENTS.mapStore.pointViewPortUpdated, {
        pointViewPort: viewPort,
      })
    },
    setSelectedExtentIds: (extentIds: string[]) =>
      setState({ selectedExtentIds: extentIds }),
    setSelectedPointId: (selectedPointId: string | null) =>
      setState({ selectedPointId }),
    toggleOrthoLayerGroupId: (id: string) => {
      const activeOrthoLayerIds = new Set(getState().activeOrthoLayerGroupIds)
      if (activeOrthoLayerIds?.has(id)) {
        activeOrthoLayerIds?.delete(id)
      } else {
        activeOrthoLayerIds?.add(id)
      }
      setState({
        activeOrthoLayerGroupIds: [...activeOrthoLayerIds],
      })
      emitter.emit(EVENTS.mapStore.activeOrthoLayersUpdated, {
        activeOrthoLayerGroupIds: [...activeOrthoLayerIds],
      })
    },
    zoomToOrthoLayer: (layerId: string) => {
      setState({
        zoomOrthoLayerGroupId: layerId,
      })
    },
  }),
})
