import { Image } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import { formatGeojson } from '@/features/AppLayout/utils'
import type { TerrapinLocation } from '@/hooks/use-terrapin.tsx'
import * as turf from '@turf/turf'
import { getLocationType, getLocationValue } from './TerrapinLocation'

interface TerrapinStaticMapDisplayProps {
  location: TerrapinLocation
}

export const TerrapinStaticMapDisplay = ({
  location,
}: TerrapinStaticMapDisplayProps) => {
  const locType = getLocationType(location)
  const locValue = getLocationValue(location)

  const staticMapUrl = createStaticMapUrl({
    geometry: turf.flip(formatGeojson(locType, locValue)),
    size: [125, 80],
    attribution: false,
    zoom: 1,
  })

  return <Image src={staticMapUrl} alt="Geometry" />
}
