import { Box, Group, Text } from '@mantine/core'
import type {
  HistogramDataPoint,
  TimelineDoctypes,
  TooltipPayload,
} from '@/features/ResultsTimeline/types'
import { getCategoryColor } from '@/features/ResultsTimeline/utils'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { datetime, formatDateWithWordMonths } from '@/utils/format-datetime'
import type { Dayjs } from 'dayjs'

const CATEGORY_LABELS: Record<TimelineDoctypes, string> = {
  [RESULT_CATEGORIES.IMAGE]: 'Images',
  [RESULT_CATEGORIES.PUBLICATION]: 'News',
  [RESULT_CATEGORIES.FIRE]: 'Fire',
  [RESULT_CATEGORIES.SHIP]: 'Ship',
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: 'Social',
  [RESULT_CATEGORIES.PROPERTY]: 'Property',
}

const formatDate = (date: Dayjs) => {
  return formatDateWithWordMonths(date.toString())
}

export const ResultsTimelineTooltip = ({
  payload,
  data,
  dateRange,
}: {
  payload?: TooltipPayload[]
  data: HistogramDataPoint[]
  dateRange: {
    min: Date
    max: Date
  }
}) => {
  if (!payload?.length || !payload[0]?.payload) return null
  const hasResults = payload.some(
    (item: { value?: number }) => item.value && item.value > 0,
  )
  if (!hasResults) return null
  const currentDate = payload[0].payload.date
  const currentBinIndex = data.findIndex((d) => d.date === currentDate)

  const startDate = datetime(currentDate)
  const endDate =
    currentBinIndex < data.length - 1
      ? datetime(data[currentBinIndex + 1].date).subtract(1, 'millisecond')
      : datetime(dateRange.max)

  const isSameDay = formatDate(startDate) === formatDate(endDate)
  const dateDisplay = isSameDay
    ? `${formatDate(startDate)} (${startDate.format('z')})`
    : `${formatDate(startDate)} - ${formatDate(endDate)} (${startDate.format('z')})`

  return (
    <Box
      p="xs"
      style={{
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        borderRadius: '4px',
        fontSize: '12px',
      }}
    >
      <Text size="xs" mb="xs">
        {dateDisplay}
      </Text>
      {payload
        .filter(
          (item: TooltipPayload) => item.name && item.value && item.value > 0,
        )
        .map(
          (item: TooltipPayload) =>
            item.name && (
              <Group key={item.name} gap="xs" m="xxs" align="center">
                <Box
                  style={{
                    width: 'var(--mantine-spacing-sm)',
                    height: 'var(--mantine-spacing-sm)',
                    borderRadius: 'var(--mantine-radius-xs)',
                    backgroundColor: getCategoryColor(item.name),
                  }}
                />
                <Text size="xs">
                  {CATEGORY_LABELS[item.name]}: {item.value}
                </Text>
              </Group>
            ),
        )}
    </Box>
  )
}
