import React, { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { MantineProvider } from '@mantine/core'
import { router } from '@/features/AppRouting'
import { graphqlClient } from '@/graphql-client'
import { theme } from '@/theme/theme'
import { DantiAuthProvider } from '@/utils/authentication'
import { DantiPostHogProvider } from '@/utils/DantiPostHogProvider'
import { QueryProvider } from '@/utils/query-provider'
import { ApolloProvider } from '@apollo/client'
import { SingletonHooksContainer } from 'react-singleton-hook'
import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/KcContext'
import { Login } from './Login'

import '@mantine/core/styles.layer.css'
import '@mantine/notifications/styles.layer.css'
import '@mantine/charts/styles.layer.css'
import '@mantine/dates/styles.layer.css'
import './main.css'

export const App = () => (
  <React.StrictMode>
    <Suspense>
      {kcLoginThemeContext === undefined ? (
        <DantiPostHogProvider>
          <DantiAuthProvider>
            <QueryProvider>
              <ApolloProvider client={graphqlClient}>
                <SingletonHooksContainer />
                <MantineProvider theme={theme}>
                  <RouterProvider router={router} />
                </MantineProvider>
              </ApolloProvider>
            </QueryProvider>
          </DantiAuthProvider>
        </DantiPostHogProvider>
      ) : (
        <MantineProvider theme={theme}>
          <Login />
        </MantineProvider>
      )}
    </Suspense>
  </React.StrictMode>
)
