import { type MutableRefObject, useEffect } from 'react'
import { useSearchMetadata } from '@/api/results'
import { actOnMap } from '@/features/DantiMap/utils'
import { useZoomToOrthoLayer } from '@/stores/map-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type { Feature, Point } from 'geojson'

export const useInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
  item?: ResultWithId,
) => {
  const searchMetadata = useSearchMetadata()
  const zoomToOrthoLayer = useZoomToOrthoLayer()

  useEffect(() => {
    const fitBoundingBox = (bbox: mapboxgl.LngLatBoundsLike) => {
      map.current?.fitBounds(bbox, { duration: 0 })
    }
    const jumpToPointCenter = (point: Feature<Point>) => {
      const [c0, c1] = point.geometry.coordinates
      map.current?.jumpTo({ center: [c0, c1], zoom: 8 })
    }
    if (item) {
      if (['Polygon', 'MultiPolygon'].includes(item.geometry.type)) {
        zoomToOrthoLayer(item.id)
      }
      if (item.geometry.type === 'Point') {
        const feature = turf.feature(item.geometry)
        jumpToPointCenter(feature)
      }
    } else if (searchMetadata?.geometry) {
      const feature = turf.feature(searchMetadata.geometry)
      const { type } = feature.geometry
      actOnMap(() => {
        if (['Polygon', 'MultiPolygon'].includes(type)) {
          const bbox = turf.bbox(feature)
          fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
        } else if (type === 'Point') {
          jumpToPointCenter(feature as Feature<Point>)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, item, searchMetadata])
}
