import { ActionIcon, Divider, Group, Pill, Stack, Text } from '@mantine/core'
import type { Collection, CollectionItem } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import { formatDatetime } from '@/utils/format-datetime'
import { isSubCollection } from '@/utils/types/result-type-checkers.ts'

export interface ListItemProps {
  data: CollectionItem | Collection
  onSelect: (collection: CollectionItem | Collection) => void
  onClick?: () => void
  icon: React.ReactNode
  date: string
  name: string
  status?: string
}

export const ListItem = ({
  data,
  onSelect,
  onClick,
  icon,
  date,
  name,
  status,
}: ListItemProps) => (
  <Stack gap="xs" px="sm" onClick={onClick}>
    <Group gap={6} wrap="nowrap">
      {icon}
      <Text c="#000000DE" size="16px" lineClamp={1}>
        {name}
      </Text>
      {!isSubCollection(data) && (
        <ActionIcon
          variant="white"
          onClick={(event) => {
            event.stopPropagation()
            onSelect(data)
          }}
          data-cy="collection-menu"
          bg="transparent"
        >
          <Icon name="more_vert" style={{ color: 'gray.2' }} />
        </ActionIcon>
      )}
    </Group>
    <Group gap="xs" align="center">
      <Text c="gray.1" size="12px">
        {formatDatetime(date)}
      </Text>
      {status === 'NEW' && (
        <Pill key="NEW" size="xs" bg="yellow.4" fw="bold">
          NEW
        </Pill>
      )}
    </Group>
    <Divider my="xs" />
  </Stack>
)
