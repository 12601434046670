import { Text } from '@mantine/core'
import { useShowScores } from '@/stores/config-store'
import type { ResultWithId } from '@/utils/types/raw-result-types.ts'

type ScoreDisplayProps = {
  result: ResultWithId
}

export function ScoreDisplay({ result }: ScoreDisplayProps) {
  const showScores = useShowScores()

  return showScores ? (
    <Text
      pos="absolute"
      bottom={0}
      right={0}
      bg="white"
      p="xxs"
      size="xs"
      fw={700}
    >
      score: {result.score}
    </Text>
  ) : null
}
