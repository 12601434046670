import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'

export function openSearchFiltersModal() {
  modals.openContextModal({
    size: 1000,
    modal: 'searchFilters',
    styles: {
      header: {
        paddingInline: 'var(--mantine-spacing-xl)',
      },
    },
    title: (
      <Title component="span" order={2}>
        Filters
      </Title>
    ),
    centered: true,
    innerProps: {},
  })
}
