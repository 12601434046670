import { type PropsWithChildren, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Overlay,
  Stack,
  Text,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import type { ContextModalProps } from '@mantine/modals'
import { SEARCH_CATEGORIES } from '@/api/results'
import {
  CameraAngle,
  CloudCover,
  Orientation,
  Quality,
} from '@/features/Filters/components'
import { Categories } from '@/features/Filters/components/Categories'
import { ImageProvidersCheckboxes } from '@/features/ImageProvidersCheckboxes'
import { useSearch } from '@/features/Search/hooks'
import {
  useAngleFilter,
  useCategoriesFilter,
  useCloudCoverFilter,
  useCustomTimeEndFilter,
  useCustomTimeStartFilter,
  useOrientationsFilter,
  usePlatformsFilter,
  useProvidersFilter,
  useQualityFilter,
  useResetFilters,
  useSensorsFilter,
  useSetFilters,
  useSourcesFilter,
} from '@/stores/filters-store'
import { TimePreset } from '@/utils/types/filter-types'

function FilterSection({
  children,
  testId,
}: PropsWithChildren<{ testId: string }>) {
  return (
    <Box
      p="md"
      h="100%"
      id={`${testId.toLowerCase()}-filters`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid var(--mantine-color-gray-3)',
        borderRadius: 'var(--mantine-radius-lg)',
      }}
    >
      {children}
    </Box>
  )
}

export function SearchFiltersModal({ context, id }: ContextModalProps) {
  const { doLastSearchWithFilters } = useSearch()

  const setFilters = useSetFilters()
  const resetFilters = useResetFilters()

  const categoriesValue = useCategoriesFilter()
  const timeStartValue = useCustomTimeStartFilter()
  const timeEndValue = useCustomTimeEndFilter()
  const qualityValue = useQualityFilter()
  const cloudCoverValue = useCloudCoverFilter()
  const cameraAngleValue = useAngleFilter()
  const orientationValue = useOrientationsFilter()

  const providersValue = useProvidersFilter()
  const sourcesValue = useSourcesFilter()
  const platformsValue = usePlatformsFilter()
  const sensorsValue = useSensorsFilter()

  const [providersResetter, setProvidersResetter] = useState(Date.now())

  const hasImages =
    categoriesValue.length === 0 || // None selected, so to the server all are selected
    categoriesValue.includes(SEARCH_CATEGORIES.IMAGE)

  function updateTimeFrame([customTimeStart, customTimeEnd]: [
    Date | null,
    Date | null,
  ]) {
    setFilters({
      timePreset: TimePreset.Custom,
      customTimeStart,
      customTimeEnd,
    })
  }

  function resetAll() {
    resetFilters()
    // Passes a new value to the ImageProvidersCheckboxes to trigger a reset
    setProvidersResetter(Date.now())
  }

  function closeModal() {
    resetAll()
    context.closeModal(id)
  }

  // The filters store expects arrays rather than Sets so we convert them here.
  function setFiltersAsArray(filters: Record<string, Set<string>>) {
    const formatted = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [key, [...value]]),
    )

    setFilters(formatted)
  }

  return (
    <Stack gap="xs" mah="60vh" px="md">
      <Divider />
      <Box mr="-xs" pr="xs" style={{ overflow: 'hidden auto', flexGrow: 1 }}>
        <Box mt="xs" mb="md">
          <Text fz="sm" fw="bold">
            Edit your search result types
          </Text>
          <Text size="sm">
            Uncheck a data type to exclude it from your search results.
          </Text>
          <Categories
            value={categoriesValue}
            setter={(categories) => setFilters({ categories })}
          />
        </Box>
        <Box pos="relative">
          {!hasImages && (
            <Overlay
              color="var(--mantine-color-white)"
              style={{ cursor: 'not-allowed' }}
            />
          )}
          <Box mt="xs" mb="md">
            <Text fz="sm" fw="bold" mb={0}>
              Edit your image search options
            </Text>
            <Text fz="sm">
              Use the menus below to customize your image search results.
            </Text>
            <Grid mt="sm">
              <Grid.Col span={{ base: 12, sm: 6, md: 4.2 }}>
                <FilterSection testId="timeframe">
                  <DatePicker
                    type="range"
                    value={[timeStartValue, timeEndValue]}
                    onChange={updateTimeFrame}
                    firstDayOfWeek={0}
                    allowSingleDateInRange
                  />
                </FilterSection>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6, md: 4.8 }}>
                <FilterSection testId="quality">
                  <Quality
                    value={qualityValue}
                    setter={(quality) => setFilters({ quality })}
                  />
                </FilterSection>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6, md: 3 }}>
                <FilterSection testId="orientation">
                  <Orientation
                    value={orientationValue}
                    setter={(orientations) => setFilters({ orientations })}
                  />
                </FilterSection>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <FilterSection testId="Cloud Cover">
                  <CloudCover
                    value={cloudCoverValue}
                    setter={(cloudCover) => setFilters({ cloudCover })}
                  />
                </FilterSection>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <FilterSection testId="Camera Angle">
                  <CameraAngle
                    value={cameraAngleValue}
                    setter={(angle) => setFilters({ angle })}
                  />
                </FilterSection>
              </Grid.Col>
            </Grid>
          </Box>
          <Box mt="sm" mb="md">
            <Text fz="sm" fw="bold">
              Edit provider and sensor options
            </Text>
            <Text fz="sm" mb="sm">
              You can select which satellite providers and sensors best fit your
              needs below.
            </Text>
            <ImageProvidersCheckboxes
              providers={new Set(providersValue)}
              sources={new Set(sourcesValue)}
              platforms={new Set(platformsValue)}
              sensors={new Set(sensorsValue)}
              setter={setFiltersAsArray}
              resetter={providersResetter}
            />
          </Box>
        </Box>
      </Box>
      <Group justify="space-between" mt="sm">
        <Button fw="normal" variant="transparent" onClick={resetAll}>
          Reset All
        </Button>
        <Group gap="xs">
          <Button fw="normal" variant="outline" c="black" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            fw="normal"
            onClick={() => {
              closeModal()
              doLastSearchWithFilters()
            }}
          >
            Apply
          </Button>
        </Group>
      </Group>
    </Stack>
  )
}
