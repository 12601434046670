import React from 'react'
import { Stack } from '@mantine/core'

type ItemWithId<T> = T & { id: string }

export const ListDisplay = <T,>({
  items,
  ItemComponent,
  MenuComponent,
}: {
  items: ItemWithId<T>[]
  ItemComponent: React.FC<{ data: T; onSelect: () => void }>
  MenuComponent: React.FC<{ data: T; onClose: () => void }>
}) => {
  const [selected, setSelected] = React.useState<ItemWithId<T>>()
  const onSelect = (item: ItemWithId<T>) => () => setSelected(item)
  const onClose = () => setSelected(undefined)

  return (
    <>
      <Stack gap={0} mt="xs" hiddenFrom="sm">
        {items.map((item) => (
          <ItemComponent key={item.id} data={item} onSelect={onSelect(item)} />
        ))}
      </Stack>
      {selected && <MenuComponent data={selected} onClose={onClose} />}
    </>
  )
}
